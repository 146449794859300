import React, {
	createContext,
	useContext,
	useEffect,
	useState,
	ReactNode,
	useCallback,
	useRef,
} from 'react';
import {
	getAccountIntegrations,
	saveSelectedPages,
	getGoogleCustomers,
	saveSelectedGoogleAccounts,
	disconnectFacebook,
	disconnectGoogleAds,
} from 'src/services/integration';
import UserContext from 'src/contexts/UserContext';
import { toastError, toastSuccess } from 'src/services/toast';
import {
	IIntegration,
	IPage,
	IGoogleIntegrationCustomers,
	IGoogleIntegration,
} from 'src/lib/schemas';
import http from 'src/services/http';

interface MyIntegrationsContextProps {
	integrations: IIntegration | null;
	isLoading: boolean;
	isSaving: boolean;
	shouldLaunch: boolean;
	setShouldLaunch: (val: boolean) => void;
	mapPages: (pages: any[]) => IPage[];
	handleSaveSelectedPages: (
		tokens: any,
		pages: IPage[],
		adAccount: any,
		redirectedFromFB?: boolean,
		igPages?: IPage[],
	) => Promise<void>;
	saveIntegrations: (
		fbIntegrationData: any,
		googleIntegrationData: any,
	) => Promise<void>;
	setFacebookIntegrationData: (data: any) => void;
	setGoogleIntegrationData: (data: any) => void;
	getSelectedPagesIds: () => string[];
	getSelectedIGAccountsIds: () => string[];
	setIntegrations: (value: IIntegration | null) => void;
	googleCustomers: IGoogleIntegrationCustomers[];
	isGoogleLoading: boolean;
	handleSaveSelectedGoogleAccounts: (
		selectedAccounts: IGoogleIntegrationCustomers[],
		saveAndPublish: boolean,
	) => Promise<void>;
	getSelectedGoogleAccountIds: () => string[];
	disconnectFacebookIntegration: () => Promise<void>;
	disconnectGoogleIntegration: () => Promise<void>;
	handleFetchIntegrations: () => Promise<void>;
	googleErrorCode: string | null;
	googleErrorMessage: string | null;
}

const MyIntegrationsContext = createContext<
	MyIntegrationsContextProps | undefined
>(undefined);

interface MyIntegrationsProviderProps {
	children: ReactNode;
}

export const MyIntegrationsProvider = ({
	children,
}: MyIntegrationsProviderProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [shouldLaunch, setShouldLaunch] = useState(false);
	const [integrations, setIntegrations] = useState<IIntegration | null>(null);
	const { user } = useContext(UserContext);
	const [googleCustomers, setGoogleCustomers] = useState<
		IGoogleIntegrationCustomers[]
	>([]);
	const [googleErrorCode, setGoogleErrorCode] = useState<string | null>(null);
	const [googleErrorMessage, setGoogleErrorMessage] = useState<string | null>(
		null,
	);
	const [isGoogleLoading, setIsGoogleLoading] = useState<boolean>(false);
	const [facebookIntegrationData, setFacebookIntegrationData] =
		useState<any>(null);
	const [googleIntegrationData, setGoogleIntegrationData] = useState<any>(null);
	const isFetchingRef = useRef(false);

	const getSelectedGoogleAccountIds = useCallback((): string[] => {
		if (!integrations) return [];
		return (
			integrations.social?.google?.attributes?.customers?.map(
				(customer: IGoogleIntegrationCustomers) => customer.id,
			) || []
		);
	}, [integrations]);

	const handleFetchGoogleCustomers = useCallback(async () => {
		if (isFetchingRef.current) {
			return;
		}

		if (!user) {
			return;
		}

		isFetchingRef.current = true;
		setIsGoogleLoading(true);
		try {
			const customers = await getGoogleCustomers(user.account);
			setGoogleCustomers(customers);
			setGoogleErrorCode(null);
			setGoogleErrorMessage(null);
		} catch (error: any) {
			if (error.code === 'revoked_token') {
				setGoogleErrorCode('revoked_token');
				setGoogleErrorMessage(error.message);
			} else if (error.code === 'permission_required') {
				setGoogleErrorCode('permission_required');
				setGoogleErrorMessage(null);
			} else {
				setGoogleErrorCode(null);
				setGoogleErrorMessage(null);
				toastError('Error fetching Google Adwords customers');
			}
		} finally {
			setIsGoogleLoading(false);
			isFetchingRef.current = false;
		}
	}, [user]);

	useEffect(() => {
		if (integrations?.social?.google?.enabled && !googleErrorCode) {
			handleFetchGoogleCustomers();
		} else if (!integrations?.social?.google?.enabled) {
			setGoogleCustomers([]);
			setGoogleErrorCode(null);
			setGoogleErrorMessage(null);
		}
	}, [
		integrations?.social?.google?.enabled,
		handleFetchGoogleCustomers,
		googleErrorCode,
	]);

	const getSelectedPagesIds = useCallback((): string[] => {
		if (!integrations) return [];
		return (
			integrations.social?.fb?.attributes?.pagesToPublishContent?.map(
				(page: IPage) => page.id,
			) || []
		);
	}, [integrations]);

	const getSelectedIGAccountsIds = useCallback((): string[] => {
		if (!integrations) return [];
		return (
			integrations.social?.fb?.attributes?.instagramPagesToPublish?.map(
				(page: IPage) => page.id,
			) || []
		);
	}, [integrations]);

	const handleSaveSelectedPages = async (
		tokens: any,
		pages: IPage[],
		adAccount: any,
		redirectedFromFB?: boolean,
		igPages?: IPage[],
	): Promise<void> => {
		if (!user || !integrations) return;

		const { instagramPagesToPublish, ...restAttributes } =
			integrations.social?.fb?.attributes || {};

		const updatedItem: any = {
			enabled: true,
			attributes: {
				...restAttributes,
				pagesToPublishContent: pages,
				adAccounts: [adAccount],
			},
		};

		if (igPages && igPages.length > 0) {
			const instagramPagesToPublishData = igPages.map((page) => ({
				id: page.id,
				username: page.name,
				image: page.image ?? '',
				access_token: tokens.accessToken,
			}));

			updatedItem.attributes.instagramPagesToPublish =
				instagramPagesToPublishData;
		}
		setIsSaving(true);

		try {
			const data = await saveSelectedPages(user.account, updatedItem);
			setIntegrations(data);
			redirectedFromFB && setShouldLaunch(true);
		} catch (error: any) {
			toastError(error);
		}
		setIsSaving(false);
	};

	const handleDisconnectFacebookIntegration = async (): Promise<void> => {
		if (!user || !integrations) return;

		try {
			await disconnectFacebook(user.account);

			setIntegrations((prev: any) => ({
				...prev,
				social: {
					...prev.social,
					fb: {
						...prev.social.fb,
						enabled: false,
						attributes: {
							...prev.social.fb.attributes,
							tokens: {},
						},
					},
				},
			}));
		} catch (error: any) {
			toastError('There was an error disconnecting Facebook integration.');
		}
	};

	const handleDisconnectGoogleIntegration = async (): Promise<void> => {
		if (!user || !integrations) return;

		try {
			await disconnectGoogleAds(user.account);

			setIntegrations((prev: any) => ({
				...prev,
				social: {
					...prev.social,
					google: {
						...prev.social.google,
						enabled: false,
						attributes: {
							...prev.social.google.attributes,
							customers: [],
							tokens: {},
						},
					},
				},
			}));
		} catch (error: any) {
			toastError(
				'There was an error disconnecting Google Adwords integration.',
			);
		}
	};

	const handleFetchIntegrations = useCallback(async (): Promise<void> => {
		if (!user) return;

		setIsLoading(true);
		try {
			const data = await getAccountIntegrations(user.account);
			setIntegrations(data);
		} catch (error: any) {
			toastError('Error fetching integrations.');
		}
		setIsLoading(false);
	}, [user]);

	useEffect(() => {
		if (!user) return;
		handleFetchIntegrations();
	}, [user, handleFetchIntegrations]);

	const mapPages = useCallback(
		(pages: any[]): IPage[] =>
			pages.map((page: any) => ({
				id: page.id,
				name: page.name,
				image: page.picture?.data?.url,
				access_token:
					page.access_token ??
					integrations?.social?.fb?.attributes?.tokens?.accessToken ??
					'',
			})),
		[integrations],
	);

	const saveIntegrations = async (
		fbIntegrationData: any,
		googleIntegrationData: any,
	): Promise<void> => {
		if (!user) return;

		setIsSaving(true);

		try {
			const integrationData: any = {};
			if (fbIntegrationData != null) {
				integrationData.fb = fbIntegrationData;
			}
			if (googleIntegrationData != null) {
				integrationData.google = googleIntegrationData;
			}
			const { data } = await http.patch(
				`/v1/integrations/${user.account}`,
				integrationData,
			);

			setIntegrations(data);

			if (shouldLaunch) {
				setShouldLaunch(false);
			}

			toastSuccess('Integrations saved successfully.');
		} catch (error: any) {
			toastError('There was an error saving your integrations.');
		} finally {
			setIsSaving(false);
		}
	};

	const handleSaveSelectedGoogleAccounts = async (
		selectedAccounts: IGoogleIntegrationCustomers[],
		saveAndPublish: boolean,
	): Promise<void> => {
		if (!user || !integrations) return;

		try {
			const token = integrations.social?.google?.attributes?.tokens;
			if (!token) throw new Error('Google token not available.');

			await saveSelectedGoogleAccounts(user.account, selectedAccounts, token);

			setIntegrations((prev: any) => ({
				...prev,
				social: {
					...prev.social,
					google: {
						enabled: true,
						attributes: {
							...prev.social.google.attributes,
							customers: selectedAccounts,
							tokens: prev.social.google.attributes.tokens,
						},
					},
				},
			}));

			if (saveAndPublish) {
				setShouldLaunch(true);
			}
		} catch (error: any) {
			toastError(
				error.message || 'There was an error saving Google Adwords accounts.',
			);
		}
	};

	const disconnectFacebookIntegration = useCallback(async () => {
		await handleDisconnectFacebookIntegration();
	}, [handleDisconnectFacebookIntegration]);

	const disconnectGoogleIntegration = useCallback(async () => {
		await handleDisconnectGoogleIntegration();
	}, [handleDisconnectGoogleIntegration]);

	return (
		<MyIntegrationsContext.Provider
			value={{
				integrations,
				isLoading,
				isSaving,
				shouldLaunch,
				setShouldLaunch,
				handleFetchIntegrations,
				saveIntegrations,
				setFacebookIntegrationData,
				setGoogleIntegrationData,
				setIntegrations,
				mapPages,
				handleSaveSelectedPages,
				getSelectedPagesIds,
				getSelectedIGAccountsIds,
				googleCustomers,
				isGoogleLoading,
				handleSaveSelectedGoogleAccounts,
				getSelectedGoogleAccountIds,
				disconnectFacebookIntegration,
				disconnectGoogleIntegration,
				googleErrorCode,
				googleErrorMessage,
			}}
		>
			{children}
		</MyIntegrationsContext.Provider>
	);
};

export const useMyIntegrations = (): MyIntegrationsContextProps => {
	const context = useContext(MyIntegrationsContext);
	if (context === undefined) {
		throw new Error('useMyIntegrations must be within MyIntegrationsProvider');
	}
	return context;
};
