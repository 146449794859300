import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Button,
	Flex,
	Text,
	VStack,
	Image,
	Alert,
	AlertIcon,
	Box,
	Divider,
} from '@chakra-ui/react';
import {
	IFailedCreative,
	IPublishedCreative,
} from 'src/lib/schemas/campaign/newFlowCampaign';
import ChannelStatusSection from './ChannelStatusSection';
import { MdWarningAmber } from 'react-icons/md';
import CustomModal from 'src/components/common/CustomModal';

interface ICampaignSuccessModalProps {
	isOpen: boolean;
	onClose: () => void;
	title: 'SUCCESS' | 'ERROR' | 'PARTIAL PUBLISHED';
	campaignName: string;
	showFacebookLinks?: boolean;
	showGoogleLinks?: boolean;
	failedCreatives: IFailedCreative[];
	publishedCreatives: IPublishedCreative[];
	selectedChannels?: string[];
	fbPageId?: string;
	igName?: string;
}

const CampaignSuccessModal: FC<ICampaignSuccessModalProps> = ({
	title,
	isOpen,
	onClose,
	fbPageId,
	igName,
	failedCreatives = [],
	publishedCreatives = [],
	selectedChannels = [],
}) => {
	const navigate = useNavigate();

	const channelInfoMap: { [key: string]: { name: string; icon: JSX.Element } } =
		{
			facebook: {
				name: 'Facebook Social',
				icon: (
					<Image
						src="/images/integrations/facebook.png"
						alt="Facebook"
						boxSize="19px"
					/>
				),
			},
			facebookPaid: {
				name: 'Facebook Paid',
				icon: (
					<Image
						src="/images/integrations/facebook.png"
						alt="Facebook Ads"
						boxSize="19px"
					/>
				),
			},
			instagram: {
				name: 'Instagram Social',
				icon: (
					<Image
						src="/images/integrations/instagram.png"
						alt="Instagram"
						boxSize="19px"
					/>
				),
			},
			instagramPaid: {
				name: 'Instagram Paid',
				icon: (
					<Image
						src="/images/integrations/instagram.png"
						alt="Instagram Ads"
						boxSize="19px"
					/>
				),
			},
			google: {
				name: 'Google Paid',
				icon: (
					<Image
						src="/images/integrations/google.png"
						alt="Google"
						boxSize="22px"
						width="auto"
					/>
				),
			},
			twitter: {
				name: 'Twitter',
				icon: (
					<Image
						src="/images/integrations/twitter.png"
						alt="Twitter"
						boxSize="19px"
						height="auto"
					/>
				),
			},
			sms: {
				name: 'SMS',
				icon: (
					<Image
						src="/images/integrations/sms.png"
						alt="SMS"
						boxSize="24px"
						height="auto"
					/>
				),
			},
			email: {
				name: 'Email',
				icon: (
					<Image
						src="/images/integrations/email.png"
						alt="Email"
						boxSize="22px"
						height="auto"
					/>
				),
			},
		};

	const placementIdToChannelIdMap: { [key: string]: string } = {
		facebookFeedSocialMedia: 'facebook',
		facebookStorySocialMedia: 'facebook',
		instagramFeedSocialMedia: 'instagram',
		instagramStorySocialMedia: 'instagram',
		facebookFeedPaidAd: 'facebookPaid',
		facebookStoryPaidAd: 'facebookPaid',
		instagramFeedPaidAd: 'instagramPaid',
		instagramStoryPaidAd: 'instagramPaid',
		googlePMAX: 'google',
		googleSEARCH: 'google',
		googleDEMANDGEN: 'google',
	};

	const channelLinks: { [key: string]: { url: string; text: string } } = {
		facebook: {
			url: `https://www.facebook.com/profile.php?id=${fbPageId}`,
			text: 'Manage your published ads',
		},
		facebookPaid: {
			url: 'https://business.facebook.com/latest/ad_center',
			text: 'Manage your published ads',
		},
		instagram: {
			url: `https://www.instagram.com/${igName}`,
			text: 'Manage your published ads',
		},
		instagramPaid: {
			url: 'https://business.facebook.com/latest/ad_center',
			text: 'Manage your published ads',
		},
		google: {
			url: 'https://ads.google.com/',
			text: 'Manage your published ads',
		},
	};

	const placementIdToCreativeName: { [key: string]: string } = {
		facebookFeedSocialMedia: 'Feed ',
		facebookStorySocialMedia: 'Story ',
		instagramFeedSocialMedia: 'Feed ',
		instagramStorySocialMedia: 'Story ',
		facebookFeedPaidAd: 'Feed ',
		facebookStoryPaidAd: 'Story ',
		instagramFeedPaidAd: 'Feed ',
		instagramStoryPaidAd: 'Story ',
		googlePMAX: 'Performance Max ',
		googleSEARCH: 'Search ',
		googleDEMANDGEN: 'Demand Gen ',
	};

	type SuccessStatus = 'success' | 'partial' | 'error';

	const getChannelIdsFromCreative = (
		creative: IFailedCreative | IPublishedCreative,
		selectedChannels: string[],
	): string[] => {
		const channelIds: string[] = [];

		if (creative.placementId) {
			const placementChannel = placementIdToChannelIdMap[creative.placementId];
			if (placementChannel) {
				channelIds.push(placementChannel);
			}
		}

		if (creative.channel) {
			if (creative.channel === 'metaAds') {
				if (selectedChannels.includes('facebookPaid')) {
					channelIds.push('facebookPaid');
				}
				if (selectedChannels.includes('instagramPaid')) {
					channelIds.push('instagramPaid');
				}
			} else if (
				creative.channel === 'googleAds' ||
				creative.channel === 'google' ||
				creative.channel === 'google_pmax'
			) {
				if (!channelIds.includes('google')) {
					channelIds.push('google');
				}
			} else {
				if (!channelIds.includes(creative.channel)) {
					channelIds.push(creative.channel);
				}
			}
		}

		if (
			channelIds.length === 0 &&
			creative.placementId &&
			creative.placementId.startsWith('google')
		) {
			if (!channelIds.includes('google')) {
				channelIds.push('google');
			}
		}

		return channelIds;
	};

	const getErrorsByChannel = (
		channelId: string,
		selectedChannels: string[],
	): { title: string; message: string; errorCode?: string }[] => {
		const errors = failedCreatives
			.filter((creative) => {
				const inferredChannelIds = getChannelIdsFromCreative(
					creative,
					selectedChannels,
				);
				return inferredChannelIds.includes(channelId);
			})
			.flatMap((creative) => {
				const placementName =
					creative.placementId &&
					placementIdToCreativeName[creative.placementId]
						? placementIdToCreativeName[creative.placementId]
						: '';
				return creative.errors.map((error) => ({
					title: placementName,
					message: error.message,
					errorCode: error.errorCode,
				}));
			});

		const uniqueErrorsMap = new Map<
			string,
			{ title: string; message: string }
		>();

		errors.forEach((error) => {
			if (!uniqueErrorsMap.has(error.message)) {
				uniqueErrorsMap.set(error.message, error);
			}
		});

		return Array.from(uniqueErrorsMap.values());
	};

	const isChannelSuccessful = (channelId: string): SuccessStatus => {
		const channelCreatives = [...publishedCreatives, ...failedCreatives].filter(
			(creative) => {
				const inferredChannelIds = getChannelIdsFromCreative(
					creative,
					selectedChannels,
				);
				return inferredChannelIds.includes(channelId);
			},
		);

		let successfulCount = 0;
		channelCreatives.forEach((creative) => {
			if ('errors' in creative) {
				const allPrevious = creative.errors.every(
					(e) => e.errorCode === 'CREATIVE_PUBLISHED',
				);
				if (allPrevious) successfulCount++;
			} else {
				successfulCount++;
			}
		});

		const total = channelCreatives.length;
		if (successfulCount === total) return 'success';
		if (successfulCount > 0) return 'partial';
		return 'error';
	};
	const channelPriority: { [key: string]: number } = {
		facebook: 1,
		facebookPaid: 2,
		instagram: 3,
		instagramPaid: 4,
		google: 5,
		twitter: 6,
		sms: 7,
		email: 8,
	};

	const channelsFromCreatives = [
		...failedCreatives,
		...publishedCreatives,
	].reduce((channelsSet, creative) => {
		const channels = getChannelIdsFromCreative(creative, selectedChannels);
		channels.forEach((channel) => channelsSet.add(channel));
		return channelsSet;
	}, new Set<string>());

	const channelsToDisplay = Array.from(
		new Set([
			...(Array.isArray(selectedChannels) ? selectedChannels : []),
			...Array.from(channelsFromCreatives),
		]),
	)
		.map((channelId) => {
			const channelInfo = channelInfoMap[channelId] || {
				name: channelId,
				icon: null,
			};

			const originalErrors = getErrorsByChannel(channelId, selectedChannels);

			const successStatus = isChannelSuccessful(channelId);

			const filteredErrors =
				successStatus === 'success'
					? originalErrors.filter(
							(error) =>
								!originalErrors.some(
									(e) => e.errorCode === 'CREATIVE_PUBLISHED',
								),
					  )
					: originalErrors;

			const comingSoonChannels = ['sms', 'email', 'twitter', 'pns'];
			const comingSoon = comingSoonChannels.includes(channelId);

			const linkInfo = channelLinks[channelId];

			const errorCount = filteredErrors.length;

			const hasSpecificError =
				originalErrors.length === 1 &&
				originalErrors.some((e) => e.errorCode === 'CREATIVE_PUBLISHED');

			return {
				channelId,
				channelName: channelInfo.name,
				channelIcon: channelInfo.icon,
				errors: filteredErrors,
				successStatus,
				comingSoon,
				linkInfo,
				hasSpecificError,
				errorCount,
				priority: channelPriority[channelId] || Infinity,
			};
		})
		.sort((a, b) => {
			const aHasWarnings = a.errorCount > 0 && !a.hasSpecificError;
			const bHasWarnings = b.errorCount > 0 && !b.hasSpecificError;

			if (aHasWarnings && !bHasWarnings) return -1;
			if (!aHasWarnings && bHasWarnings) return 1;
			if (aHasWarnings && bHasWarnings) {
				return b.errorCount - a.errorCount;
			}

			if (a.successStatus === 'partial' && b.successStatus !== 'partial')
				return -1;
			if (b.successStatus === 'partial' && a.successStatus !== 'partial')
				return 1;

			if (a.hasSpecificError && !b.hasSpecificError) return -1;
			if (b.hasSpecificError && !a.hasSpecificError) return 1;

			if (a.successStatus === 'success' && b.successStatus !== 'success')
				return -1;
			if (b.successStatus === 'success' && a.successStatus !== 'success')
				return 1;

			if (a.comingSoon && !b.comingSoon) return 1;
			if (!a.comingSoon && b.comingSoon) return -1;

			return a.priority - b.priority;
		});
	const channelsWithErrors = new Set<string>();
	channelsToDisplay.forEach((channel) => {
		if (channel.errorCount > 0 && !channel.hasSpecificError) {
			channelsWithErrors.add(channel.channelId);
		}
	});

	const hasErrors = channelsWithErrors.size > 0;

	const is500Error = failedCreatives.some((fc) =>
		fc.errors.some(
			(e) => e.message === "We couldn't published this ad, please try again",
		),
	);

	const getMainMessage = () => {
		switch (title) {
			case 'SUCCESS':
				return 'Your campaign was published';
			case 'ERROR':
				return is500Error
					? 'We could not publish the campaign, please try again'
					: 'Your campaign was not published';
			case 'PARTIAL PUBLISHED':
				return 'Your campaign was partially published';
			default:
				return '';
		}
	};

	const modalHeader = (
		<Text fontSize="md" fontWeight="bold" textAlign="center">
			{getMainMessage()}
		</Text>
	);

	const modalFooter = hasErrors ? (
		<Flex w="full" justify="center">
			<Button onClick={onClose} px={10}>
				Close
			</Button>
		</Flex>
	) : (
		<Flex gap={2} w="full" justify="space-between">
			<Button onClick={() => navigate('/projects/campaigns')} flex={1}>
				See campaigns
			</Button>
			<Button
				flex={1}
				colorScheme="secondary"
				onClick={() => {
					onClose();
					navigate('/refresh-page', {
						state: { to: '/projects/campaigns/new' },
					});
				}}
			>
				Create another campaign
			</Button>
		</Flex>
	);

	const modalBody = (
		<>
			<Flex
				gap={2}
				direction="column"
				alignItems="center"
				textAlign="center"
				w="full"
			>
				{hasErrors && !is500Error && (
					<Alert
						status="warning"
						mt={2}
						w="full"
						py={2}
						borderRadius="md"
						alignItems="center"
						justifyContent="flex-start"
					>
						<AlertIcon
							as={MdWarningAmber}
							boxSize="24px"
							color="orange.500"
							mr={2}
						/>
						<Text fontSize="sm">
							{channelsWithErrors.size}{' '}
							{channelsWithErrors.size > 1
								? 'channels require review'
								: 'channel requires review'}
						</Text>
					</Alert>
				)}
			</Flex>

			<Box w="full" maxHeight="60vh" overflowY="auto" mt={4}>
				<VStack align="start" w="full" spacing={0}>
					{channelsToDisplay.map((channel, index) => (
						<React.Fragment key={channel.channelId}>
							<ChannelStatusSection
								channelName={channel.channelName}
								channelIcon={channel.channelIcon}
								errors={channel.errors}
								successStatus={channel.successStatus}
								comingSoon={channel.comingSoon}
								linkInfo={channel.linkInfo}
								hasSpecificError={channel.hasSpecificError}
							/>
							{index < channelsToDisplay.length - 1 && <Divider />}
						</React.Fragment>
					))}
				</VStack>
			</Box>
		</>
	);

	return (
		<CustomModal
			isOpen={isOpen}
			onClose={onClose}
			header={modalHeader}
			footer={modalFooter}
			isTemplateModal={false}
			size="xl"
		>
			{modalBody}
		</CustomModal>
	);
};

export default CampaignSuccessModal;
