import React, { useEffect } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	ModalProps,
} from '@chakra-ui/react';

interface CustomModalProps extends ModalProps {
	header?: React.ReactNode;
	children: React.ReactNode;
	footer?: React.ReactNode;
	isTemplateModal?: boolean;
	modalRef?: React.RefObject<HTMLDivElement>;
	withBorder?: boolean;
	height?: string;
	minWidth?: string;
}

const CustomModal: React.FC<CustomModalProps> = ({
	isOpen,
	onClose,
	header,
	children,
	footer,
	isTemplateModal = false,
	modalRef,
	withBorder = true,
	height,
	minWidth,
	...props
}) => {
	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}

		return () => {
			document.body.style.overflow = 'auto';
		};
	}, [isOpen]);

	const isMobile = window.screen.width <= 768;

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			blockScrollOnMount={false}
			{...props}
		>
			<ModalOverlay zIndex={1800} />
			<ModalContent
				ref={modalRef}
				margin="auto"
				style={{ overflow: 'hidden', height: height || 'auto' }}
				width={isTemplateModal ? '80%' : undefined}
				minWidth={minWidth}
				{...(isTemplateModal && {
					maxH: isMobile ? '45vh' : '90vh',
					minH: isMobile ? '45vh' : '90vh',
					width: '80%',
					borderRadius: 'md',
				})}
				containerProps={{
					zIndex: 1800,
				}}
				display="flex"
				flexDirection="column"
			>
				<ModalHeader pb={isTemplateModal ? 2 : undefined}>{header}</ModalHeader>
				<ModalCloseButton />
				<ModalBody
					overflowY="auto"
					flex="1"
					borderWidth={withBorder ? '1px' : '0px'}
				>
					{children}
				</ModalBody>
				<ModalFooter>{footer}</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default CustomModal;
