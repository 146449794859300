import http from './http';
import { ITemplate } from 'src/contexts/templates/TemplatesContext';
import { ICampaign } from 'src/lib/schemas';
import { toastError } from './toast';

const apiVersion = 'v1';
const accountUrl = `/${apiVersion}/account`;

export const updateTemplate = async (
	template: ITemplate,
	templateid: string,
	scope: 'platform' | 'account',
) => {
	const { data } = await http.patch<ITemplate>(
		`${accountUrl}/templates/${templateid}?scope=${scope}`,
		template,
	);
	return data;
};

export const createTemplate = async (template: ITemplate) => {
	if (!template.id) {
		template.id = 'No title';
	}
	const { data } = await http.post<ITemplate>(
		`${accountUrl}/templates`,
		template,
	);
	return data;
};

export const makeTemplatePublic = async (templateid: string) => {
	const { data } = await http.post<ITemplate>(
		`${accountUrl}/templates/${templateid}`,
	);
	return data;
};

export const getTemplatesByIndustry = async (
	campaign?: ICampaign | null,
	queryParams: {
		isInternal?: boolean;
		extraParams?: string;
	} = {},
): Promise<{ results: ITemplate[]; facets: any[] }> => {
	try {
		const { isInternal, extraParams } = queryParams;

		let url = `${accountUrl}/templates/search`;
		const qs: string[] = [];

		if (extraParams) {
			qs.push(extraParams);
		}
		if (isInternal) {
			qs.push('is_internal=true');
		}

		if (qs.length > 0) {
			url += `?${qs.join('&')}`;
		}

		let payload: any = {};
		if (campaign) {
			const { creatives, designDirections, ...filteredCampaign } = campaign;
			payload = { ...filteredCampaign };
		}

		const { data } = await http.post<{ results: ITemplate[]; facets: any[] }>(
			url,
			payload,
		);

		return data;
	} catch (error: any) {
		toastError(error.message || 'Failed to fetch templates');
		throw new Error(
			`Failed to fetch templates${
				campaign ? ` for campaign ${campaign.id}` : ''
			}`,
		);
	}
};
export const getTemplates = async (account: string) => {
	try {
		const { data } = await http.get<ITemplate[]>(`${accountUrl}/templates`);
		return data;
	} catch (error: any) {
		throw new Error('Failed to fetch templates');
	}
};

export const getFonts = async () => {
	try {
		const { data } = await http.get<string[]>(`${accountUrl}/getFonts`);
		return data;
	} catch (error: any) {
		throw new Error('Failed to fetch templates');
	}
};

export const getTemplateById = async (
	account: string,
	templateId: string,
	scope: 'platform' | 'account',
): Promise<ITemplate> => {
	try {
		const { data } = await http.get<ITemplate>(
			`${accountUrl}/templates/${templateId}?scope=${scope}&no_cache=true`,
		);
		return data;
	} catch (error: any) {
		throw new Error(`Failed to fetch template ${templateId}`);
	}
};
