import { getImageSize } from 'react-image-size';

interface IDimensions {
	width: number;
	height: number;
}

const useImage = () => {
	const isImage = (url: string) => getImageSize(url);

	const isBase64Image = (url: string): boolean => {
		const regex =
			/^data:image\/(?:gif|png|jpeg|bmp|webp|svg|avif\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/;
		return regex.test(url);
	};

	const isSvg = (url: string) => {
		const regex = /.svg$/;
		return regex.test(url);
	};

	const isSizeValid = async (dimensions: IDimensions, url: string) => {
		if (!dimensions) return;
		try {
			const { width, height } = await getImageSize(url);
			return width >= dimensions.width && height >= dimensions.height;
		} catch (error) {
			return;
		}
	};
	const isAnySideValid = async (minSide: number, url: string) => {
		if (!minSide) return;
		try {
			const { width, height } = await getImageSize(url);
			return width >= minSide || height >= minSide;
		} catch (error) {
			console.log(error);
			return true;
		}
	};

	const validateImage = async (url: string) => {
		try {
			const isValidImage = await isImage(url);
			if (!isValidImage || isBase64Image(url) || isSvg(url)) {
				return false;
			}
		} catch (error) {
			return false;
		}

		return true;
	};

	return {
		isSizeValid,
		isAnySideValid,
		isBase64Image,
		isSvg,
		isImage,
		validateImage,
	};
};

export default useImage;
