import React, {
	FC,
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle,
	useContext,
} from 'react';
import { Flex, Button, Image } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	ChangeAccountLink,
	GoogleAuthButton,
} from 'src/components/integration';
import UserContext from 'src/contexts/UserContext';
import Chip from 'src/components/common/Chip';
import AlertMessage from 'src/components/common/AlertMessage';
import { getGoogleOauthUrl } from 'src/lib/integrations';
import GoogleAccounts from 'src/components/integration/GoogleAccounts';
import { IGoogleIntegrationCustomers } from 'src/lib/schemas';
import { useMyIntegrations } from 'src/contexts/integration/IntegrationContext';

interface GoogleIntegrationCardProps {
	isConnectedToGoogle: boolean;
	error: string | null;
	noAccountsAvailable: boolean;
	setSelectedGoogleAccount: React.Dispatch<
		React.SetStateAction<IGoogleIntegrationCustomers | null>
	>;
	redirectedFromGoogle?: boolean;
}

export interface GoogleIntegrationCardRef {
	getIntegrationData: () => any;
}

const GoogleIntegrationCard = forwardRef<
	GoogleIntegrationCardRef,
	GoogleIntegrationCardProps
>(
	(
		{
			isConnectedToGoogle,
			error,
			noAccountsAvailable,
			setSelectedGoogleAccount,
			redirectedFromGoogle,
		},
		ref,
	) => {
		const { user } = useContext(UserContext);

		const {
			integrations,
			disconnectGoogleIntegration,
			googleErrorCode,
			googleErrorMessage,
		} = useMyIntegrations();

		const [connected, setConnected] = useState<boolean | undefined>(
			isConnectedToGoogle,
		);

		const [localError, setLocalError] = useState<string | null>(null);
		const [selectedAccount, setSelectedAccount] =
			useState<IGoogleIntegrationCustomers | null>(null);

		const googleTokens = integrations?.social?.google?.attributes?.tokens;

		const location = useLocation();
		const navigate = useNavigate();

		useEffect(() => {
			if (googleErrorCode === 'revoked_token') {
				setConnected(false);
			}
		}, [googleErrorCode]);

		useEffect(() => {
			setConnected(isConnectedToGoogle);
		}, [isConnectedToGoogle]);

		useEffect(() => {
			if (error) {
				setConnected(false);
			}
		}, [error]);

		if (!user) return null;

		const handleDisconnectGoogle = async () => {
			try {
				await disconnectGoogleIntegration();
				setConnected(false);
				setLocalError(null);
				setSelectedAccount(null);
				setSelectedGoogleAccount(null);
			} catch (err) {
				console.error('Error disconnecting Google Adwords integration:', err);
				setLocalError('Error disconnecting Google Adwords integration.');
			}
		};

		const handleSelectedAccountChange = (
			account: IGoogleIntegrationCustomers | null,
		) => {
			setSelectedAccount(account);
			setSelectedGoogleAccount(account);
		};

		useImperativeHandle(ref, () => ({
			getIntegrationData: () => {
				if (!selectedAccount) {
					setLocalError('Please select a Google Adwords account.');
					return null;
				}
				return {
					enabled: true,
					attributes: {
						customers: [selectedAccount],
						tokens: googleTokens,
					},
				};
			},
		}));

		return (
			<Flex
				boxShadow="0 0px 15px -3px rgba(0, 0, 0, 0.1)"
				p={5}
				borderRadius="8px"
				w="full"
				maxW="600px"
			>
				<Flex gap={8} direction="column" w="full">
					{(error || localError) && (
						<AlertMessage status="error">{error || localError}</AlertMessage>
					)}

					{googleErrorCode === 'revoked_token' && googleErrorMessage && (
						<AlertMessage status="error">{googleErrorMessage}</AlertMessage>
					)}

					{connected ? (
						<Flex direction="column" gap={5}>
							<Flex
								gap={2}
								alignItems="center"
								justifyContent="space-between"
								w="full"
							>
								<Flex gap={2} alignItems="center">
									<Image
										src="/images/GoogleLogo.svg"
										alt="Google"
										width="55px"
										height="24px"
									/>
									<Chip label="Connected" tagProps={{ colorScheme: 'green' }} />
								</Flex>
								<Flex gap={2} alignItems="center">
									<ChangeAccountLink
										link={getGoogleOauthUrl({
											account: user.account,
											redirectTo: `${location.pathname}?fromGoogle`,
										})}
									/>
									<Button
										onClick={handleDisconnectGoogle}
										bg="#E5E7EB"
										color="black"
										size="sm"
										_hover={{ bg: '#D1D5DB' }}
									>
										Disconnect
									</Button>
								</Flex>
							</Flex>

							<GoogleAccounts
								onSelectedAccountChange={handleSelectedAccountChange}
							/>
						</Flex>
					) : (
						<Flex
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							w="full"
						>
							<Flex direction="row" alignItems="center" gap={4}>
								<Image
									src="/images/GoogleLogo.svg"
									alt="Google"
									width="55px"
									height="24px"
								/>
								<Chip label="Disconnected" tagProps={{ colorScheme: 'red' }} />
							</Flex>
							<GoogleAuthButton account={user.account} />
						</Flex>
					)}
				</Flex>
			</Flex>
		);
	},
);

GoogleIntegrationCard.displayName = 'GoogleIntegrationCard';

export default GoogleIntegrationCard;
