import {
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	IconButton,
	Spinner,
	Toast,
	useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import {
	MdArchive,
	MdContentCopy,
	MdDelete,
	MdOutlineArchive,
	MdOutlineDelete,
} from 'react-icons/md';
import { toastError, toastSuccess } from 'src/services/toast';

interface CampaignOptionsMenuProps {
	onDuplicate: () => Promise<void>;
	onDelete: () => Promise<void>;
}

const CampaignOptionsMenu = ({
	onDuplicate,
	onDelete,
}: CampaignOptionsMenuProps) => {
	const [isDeleting, setIsDeleting] = useState(false);
	const [isDuplicating, setIsDuplicating] = useState(false);

	const handleDeleteClick = async () => {
		setIsDeleting(true);
		try {
			await onDelete();
			toastSuccess('The campaign has been successfully deleted.');
		} catch (error) {
			toastError('There was an error deleting the campaign.');
		} finally {
			setIsDeleting(false);
		}
	};

	const handleDuplicateClick = async () => {
		setIsDuplicating(true);
		try {
			await onDuplicate();
			toastSuccess('The campaign has been successfully duplicated.');
		} finally {
			setIsDuplicating(false);
			toastError('There was an error duplicating the campaign.');
		}
	};

	return (
		<Menu closeOnSelect={false}>
			<MenuButton
				as={IconButton}
				aria-label="Options"
				icon={<BsThreeDotsVertical />}
				variant="ghost"
				size="sm"
			/>
			<MenuList>
				<MenuItem
					onClick={handleDuplicateClick}
					isDisabled={isDuplicating}
					icon={<MdContentCopy size={15} />}
				>
					{isDuplicating ? (
						<Spinner size="sm" color="orange.500" />
					) : (
						'Duplicate'
					)}
				</MenuItem>
				<MenuItem
					onClick={handleDeleteClick}
					isDisabled={isDeleting}
					icon={<MdDelete size={15} />}
				>
					{isDeleting ? <Spinner size="sm" color="red.500" /> : 'Delete'}
				</MenuItem>
			</MenuList>
		</Menu>
	);
};

export default CampaignOptionsMenu;
