import React, { FC, useEffect, useRef, useState } from 'react';
import {
	Flex,
	Text,
	FormControl,
	FormLabel,
	Box,
	Input,
	InputGroup,
	InputLeftElement,
	InputRightElement,
	Skeleton,
	FormControlProps,
	FormLabelProps,
} from '@chakra-ui/react';
import {
	ChevronRightIcon,
	ChevronLeftIcon,
	SearchIcon,
	ChevronDownIcon,
} from '@chakra-ui/icons';

import { IIndustry } from 'src/services/industries';
import { isEmpty, omit } from 'lodash';

interface IMultiLevelDropdown {
	items: any[] | null;
	onSelect: (value: IIndustry) => void;
	label?: string;
	subLabel?: string;
	requirementsLabel?: string;
	required?: boolean;
	initialValue?: any;
	isLoading?: boolean;
	formLabelProps?: FormLabelProps;
	formControlProps?: FormControlProps;
	onOpen?: () => void;
	onClose?: () => void;
}

const MultiLevelDropdown: FC<IMultiLevelDropdown> = ({
	items,
	onSelect,
	label,
	subLabel,
	requirementsLabel,
	required,
	initialValue,
	isLoading = false,
	formLabelProps,
	formControlProps,
	onOpen,
	onClose,
}) => {
	const [selectedItem, setSelectedItem] = useState<any | undefined>(
		initialValue,
	);
	const [options, setOptions] = useState<any[] | null>(items);
	const [filteredOptions, setFilteredOptions] = useState<any[] | null>(items);
	const [prevOptions, setPrevOptions] = useState<any>({});
	const [optionsLevel, setOptionsLevel] = useState(0);
	const [isOpen, setIsOpen] = useState(false);
	const [isSearching, setIsSearching] = useState(false);
	const ref = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const [inputValue, setInputValue] = useState(initialValue?.name || '');

	useEffect(() => {
		if (!initialValue) return;
		setSelectedItem(initialValue);
		setInputValue(initialValue.name);
	}, [initialValue]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				setIsOpen(false);
				setIsSearching(false);
				setInputValue(selectedItem?.name || '');
				if (onClose) onClose();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, selectedItem, onClose]);

	useEffect(() => {
		if (items) {
			setOptions(items);
			setFilteredOptions(items);
		}
	}, [items]);

	useEffect(() => {
		if (options && isSearching) {
			const filtered = options.filter((item) =>
				item.name.toLowerCase().includes(inputValue.toLowerCase()),
			);
			setFilteredOptions(filtered);
		}
	}, [inputValue, options, isSearching]);

	const renderLabelIndicator = () => {
		if (!required) return null;
		return <Text color="#e53e3e">*</Text>;
	};

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setInputValue(value);
		setIsSearching(true);
		if (!isOpen) {
			setIsOpen(true);
			if (onOpen) onOpen();
		}
	};

	const handleInputFocus = () => {
		setIsSearching(true);
		setInputValue('');
		if (!isOpen) {
			setIsOpen(true);
			if (onOpen) onOpen();
		}
	};

	const handleSelectItem = (item: any) => {
		if (item.children && item.children.length > 0) {
			setPrevOptions({
				...prevOptions,
				[optionsLevel]: options,
			});
			setOptions(item.children);
			setOptionsLevel((prevState) => prevState + 1);
			setInputValue('');
			setIsSearching(true);
		} else {
			setSelectedItem(item);
			setInputValue(item.name);
			onSelect(item);
			setIsOpen(false);
			setIsSearching(false);
			if (onClose) onClose();
		}
	};

	return (
		<FormControl {...formControlProps} ref={ref}>
			<Flex direction="column" gap={3.5} mt={-2}>
				{label && (
					<FormLabel
						fontSize="14px"
						{...formLabelProps}
						fontWeight="500"
						mb="10px"
						h="24px"
					>
						<Flex gap={1} alignItems="center" h="32px">
							{label}
							{subLabel && (
								<Text color="#718096" fontSize="12px">
									{subLabel}
								</Text>
							)}
							{renderLabelIndicator()}
						</Flex>
						{requirementsLabel && (
							<Text fontSize="12px" color="#959595" mt={1}>
								{requirementsLabel}
							</Text>
						)}
					</FormLabel>
				)}
				{isLoading ? (
					<Skeleton h="40px" w="full" borderRadius="5px" />
				) : (
					<InputGroup>
						<InputLeftElement pointerEvents="none">
							<SearchIcon color="#B8C2D0" />
						</InputLeftElement>
						<Input
							ref={inputRef}
							placeholder="Select/search"
							value={inputValue}
							onChange={handleInputChange}
							onFocus={handleInputFocus}
							readOnly={isLoading}
							height="40px"
							fontSize="14px"
							borderColor="#E2E8F0"
							_hover={{ borderColor: 'gray.300' }}
							_focus={{
								borderColor: '#2C6ECB',
								boxShadow: '0 0 0 1px #2C6ECB',
							}}
						/>
						<InputRightElement>
							<ChevronDownIcon color="#2D3748" w={5} h={5} />
						</InputRightElement>
					</InputGroup>
				)}
			</Flex>
			{isOpen && !isLoading && (
				<Box
					position="absolute"
					zIndex={9999}
					width="100%"
					bg="white"
					boxShadow="0 0 0 1px rgba(0,0,0,.1), 0 4px 11px rgba(0,0,0,.1)"
					borderRadius="5px"
					mt={1}
					maxH="300px"
					overflowY="auto"
				>
					{!isEmpty(prevOptions) && (
						<Flex
							alignItems="center"
							p="8px 16px"
							cursor="pointer"
							_hover={{ bg: '#f9f9fa' }}
							onClick={() => {
								setOptions(prevOptions[optionsLevel - 1]);
								setPrevOptions((prevState: any) =>
									omit(prevState, optionsLevel - 1),
								);
								setOptionsLevel((prevState) => prevState - 1);
								setInputValue('');
								setIsSearching(true);
							}}
							fontSize="14px"
							borderBottom="1px solid #E2E8F0"
						>
							<ChevronLeftIcon mr={2} />
							Back
						</Flex>
					)}
					{filteredOptions?.map((item) => (
						<Flex
							key={item.name}
							alignItems="center"
							justifyContent="space-between"
							p="8px 16px"
							cursor="pointer"
							_hover={{ bg: '#f9f9fa' }}
							onClick={() => handleSelectItem(item)}
							fontSize="14px"
							borderBottom="1px solid #E2E8F0"
						>
							{item.name}
							{item.children && item.children.length > 0 && (
								<ChevronRightIcon />
							)}
						</Flex>
					))}
				</Box>
			)}
		</FormControl>
	);
};

export default MultiLevelDropdown;
