import { uploadFile } from 'src/services/fileUpload';
import { z } from 'zod';

export interface IDomainDataSchema {
	name: string;
	industry?: any;
	keywords?: string[];
	logo?: string;
	font?: string;
	description?: string;
}

export const DomainDataSchema: z.ZodType<IDomainDataSchema> = z.object({
	name: z.string().nonempty('Business name is required'),
	industry: z.any().refine((value) => value !== '', {
		message: 'Industry is required',
	}),
	logo: z.string().superRefine(async (url, ctx) => {
		try {
			await uploadFile(url, false, 100, 30);
		} catch (error: any) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: error.response?.data?.message || 'Logo URL is not valid',
			});
		}
	}),
	description: z.string().optional(),
	font: z.string().optional(),
	keywords: z.array(z.string()).optional(),
});
