import React, { useEffect, useState, useContext, useMemo, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import {
	Box,
	Heading,
	Text,
	Skeleton,
	Button,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import TemplateContext from 'src/contexts/templates/TemplatesContext';
import IndustryLabelSelector from 'src/components/common/IndustryLabelSelector';
import { useNavigate } from 'react-router-dom';
import SelectTemplateModal from 'src/components/campaigns/design-directions/SelectTemplateModal';
import UserContext from 'src/contexts/UserContext';

const TemplatesGallery: React.FC = () => {
	const {
		templates,
		templatesByScope,
		setSelectedTemplateIds,
		isSelectTemplateModalOpen,
		setSelectTemplateModalOpen,
		industries,
		industriesLoaded,
		fetchTemplatesByScope,
		templatesLoaded,
		fetchIndustries,
	} = useContext(TemplateContext);

	const [scope, setScope] = useState<string>('all');
	const [isBeginning, setIsBeginning] = useState<boolean>(true);
	const [isEnd, setIsEnd] = useState<boolean>(false);
	const swiperRef = useRef<any>(null);
	const navigate = useNavigate();
	const [currentSelectedTemplatesIds, setCurrentSelectedTemplatesIds] =
		useState<string[]>([]);
	const { user, account } = useContext(UserContext);

	const filteredTemplates = useMemo(() => {
		if (!industriesLoaded || !templatesLoaded || !user) return [];

		let result = [];

		if (scope === 'my_templates') {
			result = templates;
		} else if (scope === 'all') {
			const myTemplates = templates.sort(
				(a, b) => (b.score || 0) - (a.score || 0),
			);
			const platformTemplates = templatesByScope
				.filter(
					(template) =>
						!templates.some((myTemplate) => myTemplate.id === template.id),
				)
				.sort((a, b) => (b.score || 0) - (a.score || 0));

			result = [...myTemplates, ...platformTemplates];
		} else {
			result = templatesByScope
				.filter((template) => template.attributes?.industries?.includes(scope))
				.sort((a, b) => (b.score || 0) - (a.score || 0));
		}

		return result;
	}, [
		scope,
		templates,
		templatesByScope,
		industriesLoaded,
		templatesLoaded,
		user,
	]);

	const slideSize = '200px';

	const handlePrevSlide = () => {
		if (swiperRef.current) {
			swiperRef.current.slidePrev();
		}
	};

	const handleNextSlide = () => {
		if (swiperRef.current) {
			swiperRef.current.slideNext();
		}
	};

	const handleTemplateClick = (templateId: string) => {
		setCurrentSelectedTemplatesIds([templateId]);
		setSelectedTemplateIds([templateId]);
		setSelectTemplateModalOpen(true);
	};
	useEffect(() => {
		fetchTemplatesByScope();
		// fetchIndustries();
	}, []);

	return (
		<Box position="relative" overflow="visible" pt="10px">
			<Heading mb={2} color="gray.900" fontWeight={500}>
				Start an ad by choosing a template
			</Heading>
			<IndustryLabelSelector
				selectedScope={scope}
				industriesLoaded={industriesLoaded}
				onScopeChange={(newScope) => setScope(newScope)}
				templatesByScope={templatesByScope}
				templates={templates}
				industries={industries}
			/>
			{!industriesLoaded || !templatesLoaded ? (
				<Box>
					<Swiper
						modules={[Pagination]}
						pagination={false}
						spaceBetween={20}
						slidesPerView="auto"
						loop={false}
						style={{
							paddingBottom: '20px',
							paddingLeft: '20px',
							paddingRight: '20px',
						}}
						onSwiper={(swiper) => {
							swiperRef.current = swiper;
							swiper.on('slideChange', () => {
								setIsBeginning(swiper.isBeginning);
								setIsEnd(swiper.isEnd);
							});
						}}
					>
						{Array.from({ length: 5 }).map((_, index) => (
							<SwiperSlide
								key={index}
								style={{
									width: slideSize,
									height: slideSize,
									overflow: 'visible',
								}}
							>
								<Box
									borderWidth="1px"
									borderRadius="md"
									overflow="hidden"
									boxShadow="md"
									width="100%"
									height="100%"
									bg="white"
								>
									<Skeleton height="100%" width="100%" borderRadius="md" />
								</Box>
							</SwiperSlide>
						))}
					</Swiper>
				</Box>
			) : filteredTemplates.length > 0 ? (
				<Box position="relative" overflow="visible" padding="0 10px">
					<Button
						position="absolute"
						top="50%"
						left="0"
						transform="translateY(-50%)"
						zIndex="2"
						onClick={handlePrevSlide}
						bg="white"
						borderRadius="full"
						boxShadow="md"
						width="30px"
						height="30px"
						minWidth="auto"
						padding="0"
						_hover={{ bg: 'gray.100' }}
						disabled={isBeginning}
						style={{ display: isBeginning ? 'none' : 'block' }}
					>
						<ChevronLeftIcon
							w={6}
							h={6}
							color={isBeginning ? 'gray.400' : 'black'}
						/>
					</Button>
					<Swiper
						modules={[Pagination]}
						pagination={false}
						spaceBetween={20}
						slidesPerView="auto"
						loop={false}
						style={{
							paddingBottom: '20px',
							paddingTop: '10px',
							paddingLeft: '5px',
						}}
						onSwiper={(swiper) => {
							swiperRef.current = swiper;
							swiper.on('slideChange', () => {
								setIsBeginning(swiper.isBeginning);
								setIsEnd(swiper.isEnd);
							});
						}}
					>
						{filteredTemplates.map((template) => (
							<SwiperSlide
								key={template.id}
								style={{
									width: slideSize,
									height: slideSize,
									overflow: 'visible',
									cursor: 'pointer',
								}}
								onClick={() => handleTemplateClick(template.id)}
							>
								<Box
									position="relative"
									role="group"
									borderWidth="1px"
									borderRadius="md"
									overflow="hidden"
									boxShadow="md"
									width="100%"
									height="100%"
									bg="white"
									_hover={{ transform: 'scale(1.05)', borderColor: 'gray.300' }}
									transition="transform 0.2s, border-color 0.2s"
								>
									<img
										src={
											template.attributes?.thumbnail ||
											'https://via.placeholder.com/220'
										}
										alt={template.name}
										style={{
											width: '100%',
											height: '100%',
											objectFit: 'cover',
										}}
									/>
									<Box
										position="absolute"
										bottom="0"
										width="100%"
										height="100%"
										bg="blackAlpha.700"
										display="flex"
										justifyContent="center"
										alignItems="center"
										opacity="0"
										transition="opacity 0.2s"
										_groupHover={{ opacity: 1 }}
										padding="8px"
									>
										<Text
											color="white"
											fontSize="14px"
											fontFamily="Noto Sans"
											textAlign="center"
										>
											Click to generate an ad with this design
										</Text>
									</Box>
								</Box>
							</SwiperSlide>
						))}
					</Swiper>
					<Button
						position="absolute"
						top="50%"
						right="0"
						transform="translateY(-50%)"
						zIndex="2"
						onClick={handleNextSlide}
						bg="white"
						borderRadius="full"
						boxShadow="md"
						width="30px"
						height="30px"
						minWidth="auto"
						padding="0"
						_hover={{ bg: 'gray.100' }}
						disabled={isEnd}
						style={{ display: isEnd ? 'none' : 'block' }}
					>
						<ChevronRightIcon
							w={6}
							h={6}
							color={isEnd ? 'gray.400' : 'black'}
						/>
					</Button>
				</Box>
			) : (
				<Text>No templates found for the selected industry.</Text>
			)}
			{isSelectTemplateModalOpen && (
				<SelectTemplateModal
					isOpen={isSelectTemplateModalOpen}
					onClose={() => setSelectTemplateModalOpen(false)}
					source="gallery"
				/>
			)}
		</Box>
	);
};

export default TemplatesGallery;
