import React, {
	forwardRef,
	useEffect,
	useState,
	useImperativeHandle,
	useContext,
} from 'react';
import { Box, Button, Flex, Skeleton, Text } from '@chakra-ui/react';
import GoogleAccountsList from './GoogleAccountsList';
import { IGoogleIntegrationCustomers } from 'src/lib/schemas';
import { useMyIntegrations } from 'src/contexts/integration/IntegrationContext';
import { useLocation } from 'react-router-dom';
import { getGoogleOauthUrl } from 'src/lib/integrations';
import UserContext from 'src/contexts/UserContext';
import { toastError } from 'src/services/toast';

interface GoogleAccountsProps {
	onSelectedAccountChange: (
		account: IGoogleIntegrationCustomers | null,
	) => void;
}

export interface GoogleAccountsRef {
	save: () => Promise<void>;
}

const GoogleAccounts = forwardRef<GoogleAccountsRef, GoogleAccountsProps>(
	({ onSelectedAccountChange }, ref) => {
		const {
			integrations,
			googleCustomers,
			isGoogleLoading,
			getSelectedGoogleAccountIds,
			handleSaveSelectedGoogleAccounts,
			isSaving,
			shouldLaunch,
			setShouldLaunch,
			googleErrorCode,
		} = useMyIntegrations();
		const { pathname } = useLocation();
		const { user } = useContext(UserContext);

		const [selectedAccount, setSelectedAccount] =
			useState<IGoogleIntegrationCustomers | null>(null);
		const [isDataLoaded, setIsDataLoaded] = useState(false);
		const [accountError, setAccountError] = useState<string | undefined>();
		const [isAutoSaving, setIsAutoSaving] = useState<boolean>(false);
		const [isUserAction, setIsUserAction] = useState<boolean>(false);

		const saveAndPublish = pathname.includes('/projects/campaigns/');

		useEffect(() => {
			if (!isGoogleLoading && !googleErrorCode) {
				const selectedIds = getSelectedGoogleAccountIds();
				if (selectedIds.length > 0) {
					const preselected = googleCustomers.find((customer) =>
						selectedIds.includes(customer.id),
					);
					setSelectedAccount(preselected || null);
					onSelectedAccountChange(preselected || null);
				} else if (googleCustomers.length > 0) {
					const firstCustomer = googleCustomers[0];
					setSelectedAccount(firstCustomer);
					onSelectedAccountChange(firstCustomer);
				} else {
					setSelectedAccount(null);
					onSelectedAccountChange(null);
				}
				setIsDataLoaded(true);
			}
		}, [
			googleCustomers,
			getSelectedGoogleAccountIds,
			onSelectedAccountChange,
			isGoogleLoading,
			googleErrorCode,
		]);

		useEffect(() => {
			if (!isDataLoaded) return;
			if (isUserAction) {
				autoSave();
			}
		}, [selectedAccount]);

		const handleSelect = (customer: IGoogleIntegrationCustomers) => {
			setIsUserAction(true);
			setSelectedAccount(customer);
			setAccountError(undefined);
		};

		const handleSubmit = async (saveAndPublish: boolean) => {
			let hasError = false;

			if (!selectedAccount) {
				setAccountError('Please select a Google Adwords account');
				hasError = true;
			} else {
				setAccountError(undefined);
			}

			if (hasError) {
				return;
			}

			const dataToSave = {
				customers: [selectedAccount!],
				tokens: integrations?.social?.google?.attributes?.tokens,
			};

			await handleSaveSelectedGoogleAccounts(
				[selectedAccount!],
				saveAndPublish,
			);

			if (saveAndPublish && shouldLaunch) {
				setShouldLaunch(false);
			}
		};

		const autoSave = async () => {
			if (isSaving || isAutoSaving) {
				return;
			}

			setIsAutoSaving(true);
			try {
				await handleSubmit(false);
			} catch (error) {
			} finally {
				setIsAutoSaving(false);
				setIsUserAction(false);
			}
		};

		useImperativeHandle(ref, () => ({
			save: () => handleSubmit(true),
		}));

		const connectUrl = user
			? getGoogleOauthUrl({
					account: user.account,
					redirectTo: `${pathname}?fromGoogle`,
			  })
			: '';

		if (googleErrorCode === 'permission_required') {
			return (
				<Flex
					direction="row"
					align="center"
					p={4}
					bg="#FEF3C7"
					borderRadius="lg"
				>
					<Text flex="1" fontSize="sm" color="#111827" fontWeight="500">
						We need access to your Google Adwords account.
					</Text>
					<Button
						color="#FFFFFF"
						bg="#F7480B"
						_hover={{ bg: '#D63A0A' }}
						size="sm"
						onClick={() => {
							if (connectUrl) {
								window.location.href = connectUrl;
							} else {
								toastError('Unable to determine the authorization URL.');
							}
						}}
					>
						Grant access
					</Button>
				</Flex>
			);
		}

		if (isGoogleLoading || !isDataLoaded) {
			return (
				<Flex direction="column" gap={4} p={4}>
					<Skeleton height="20px" width="50%" borderRadius={50} />
					<Skeleton height="20px" width="70%" borderRadius={50} />
					<Skeleton height="20px" width="60%" borderRadius={50} />
				</Flex>
			);
		}

		if (isDataLoaded && googleCustomers.length === 0) {
			return (
				<Flex
					direction="row"
					align="center"
					p={4}
					bg="#FEF3C7"
					borderRadius="lg"
				>
					<Text flex="1" fontSize="sm" color="#111827" fontWeight="500">
						You need to create a Google Adwords account.
					</Text>
					<Button
						color="#FFFFFF"
						bg="#F7480B"
						_hover={{ bg: '#D63A0A' }}
						size="sm"
						onClick={() =>
							window.open('https://ads.google.com/home/', '_blank')
						}
					>
						Create Google Adwords Account
					</Button>
				</Flex>
			);
		}

		return (
			<Box>
				<GoogleAccountsList
					title=""
					customers={googleCustomers}
					selectedCustomer={selectedAccount}
					onSelect={handleSelect}
					error={accountError}
				/>
				<Flex justifyContent="flex-end" mt={4}>
					{/* Optional save button */}
					{/* <Button
			  colorScheme="secondary"
			  isLoading={isSaving}
			  loadingText="Saving..."
			  onClick={() => handleSubmit(saveAndPublish)}
			>
			  {saveAndPublish ? 'Save and publish' : 'Save'}
			</Button> */}
				</Flex>
			</Box>
		);
	},
);

GoogleAccounts.displayName = 'GoogleAccounts';

export default GoogleAccounts;
