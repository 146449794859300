import {
	Button,
	Text,
	IconButton,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import Chip from 'src/components/common/Chip';

import { ICampaignsListingItem, IGroup } from 'src/lib/schemas';
import CampaignOptionsMenu from './CampaignOptionsMenu';

export interface ICampaignsListingColumn extends ICampaignsListingItem {
	action?: unknown;
	options?: unknown;
}

const columnHelper = createColumnHelper<ICampaignsListingColumn>();

interface IGetCampaignsListingColumns {
	onViewCampaign: (id: string) => void;
	onDeleteCampaign: (id: string) => Promise<void>;
	onDuplicateCampaign: (id: string) => Promise<void>;
	groups: IGroup[];
	simplified?: boolean;
}

export const getCampaignsListingColumns = ({
	onViewCampaign,
	onDeleteCampaign,
	onDuplicateCampaign,
	groups,
	simplified,
}: IGetCampaignsListingColumns) => {
	if (simplified)
		return [
			columnHelper.accessor('title', {
				header: 'Campaign Title',
				cell: (info) => {
					const value = info.getValue();
					const title = value?.trim();
					return (
						<Text fontStyle={!title ? 'italic' : 'normal'}>
							{!title ? 'No title' : title}
						</Text>
					);
				},
			}),
			columnHelper.accessor('action', {
				header: '',
				meta: {
					ThProps: {
						width: '80px',
					},
					TdProps: {
						textAlign: 'center',
					},
				},
				cell: (info) => (
					<Button
						variant="orangeOutline"
						size="sm"
						onClick={() => {
							onViewCampaign(info.row.original.id);
						}}
					>
						Edit
					</Button>
				),
			}),
			columnHelper.accessor('options', {
				header: '',
				meta: {
					ThProps: {
						width: '50px',
					},
					TdProps: {
						textAlign: 'center',
					},
				},
				cell: (info) => (
					<CampaignOptionsMenu
						onDuplicate={() => onDuplicateCampaign(info.row.original.id)}
						onDelete={() => onDeleteCampaign(info.row.original.id)}
					/>
				),
			}),
		];
	else {
		return [
			columnHelper.accessor('group', {
				header: 'Campaign Group',
				cell: (info) => {
					const groupId = info.row.original.group;
					const group = groups.find((g) => g.id === groupId);
					return group?.name ?? '';
				},
			}),
			columnHelper.accessor('title', {
				header: 'Campaign Title',
				cell: (info) => {
					const value = info.getValue();
					const title = value?.trim();
					return (
						<Text fontStyle={!title ? 'italic' : 'normal'}>
							{!title ? 'No title' : title}
						</Text>
					);
				},
			}),
			columnHelper.accessor('status', {
				header: 'Status',
				cell: (info) => {
					const status = info.getValue();
					return (
						<Chip
							label={status === 'draft' ? 'Draft' : 'Published'}
							tagProps={{
								colorScheme: status === 'draft' ? 'gray' : 'green',
								variant: 'outline',
							}}
						/>
					);
				},
			}),
			columnHelper.accessor('action', {
				header: '',
				meta: {
					ThProps: {
						width: '80px',
					},
					TdProps: {
						textAlign: 'center',
					},
				},
				cell: (info) => (
					<Button
						variant="orangeOutline"
						size="sm"
						onClick={() => {
							onViewCampaign(info.row.original.id);
						}}
					>
						Edit
					</Button>
				),
			}),
			columnHelper.accessor('options', {
				header: '',
				meta: {
					ThProps: {
						width: '50px',
					},
					TdProps: {
						textAlign: 'center',
					},
				},
				cell: (info) => (
					<CampaignOptionsMenu
						onDuplicate={() => onDuplicateCampaign(info.row.original.id)}
						onDelete={() => onDeleteCampaign(info.row.original.id)}
					/>
				),
			}),
		];
	}
};
