import React, { FC } from 'react';
import {
	Box,
	Text,
	VStack,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	HStack,
	Badge,
	Link,
	Icon,
} from '@chakra-ui/react';
import { CheckCircleIcon, InfoIcon } from '@chakra-ui/icons';
import { MdWarningAmber } from 'react-icons/md';

interface ErrorDetail {
	title: string;
	message: string;
}

type SuccessStatus = 'success' | 'partial' | 'error';

interface ChannelStatusSectionProps {
	channelName: string;
	channelIcon: JSX.Element | null;
	errors?: ErrorDetail[];
	successStatus: SuccessStatus;
	comingSoon?: boolean;
	linkInfo?: { url: string; text: string };
	hasSpecificError?: boolean;
}

const ChannelStatusSection: FC<ChannelStatusSectionProps> = ({
	channelName,
	channelIcon,
	errors = [],
	successStatus,
	comingSoon = false,
	linkInfo,
	hasSpecificError = false,
}) => {
	const hasErrors = errors.length > 0;

	const renderTitle = (isAccordionButton: boolean = false) => {
		let badge = null;

		if (comingSoon) {
			badge = (
				<Badge
					colorScheme="blue"
					borderRadius="full"
					px="3px"
					pr={2}
					fontWeight="regular"
					py="2px"
					textTransform="none"
					display="flex"
					alignItems="center"
					gap="1"
				>
					<InfoIcon color="blue.500" boxSize={4} aria-label="Information" />
					Coming soon
				</Badge>
			);
		} else if (successStatus === 'success') {
			badge = (
				<Badge
					display="flex"
					alignItems="center"
					gap="1"
					colorScheme="green"
					borderRadius="full"
					px="6px"
					pr={2}
					py="2px"
					textTransform="capitalize"
				>
					<CheckCircleIcon boxSize={4} />
					<Text fontWeight="400" fontSize="xs">
						Published
					</Text>
				</Badge>
			);
		} else if (successStatus === 'partial') {
			if (hasSpecificError) {
				badge = (
					<Badge
						colorScheme="blue"
						borderRadius="full"
						px="6px"
						pr={2}
						py="2px"
						textTransform="capitalize"
						display="flex"
						alignItems="center"
						gap="1"
					>
						<InfoIcon color="blue.500" boxSize={4} aria-label="Information" />
						<Text fontWeight="400" fontSize="xs">
							Partially published
						</Text>
					</Badge>
				);
			} else {
				badge = (
					<Badge
						display="flex"
						alignItems="center"
						gap="1"
						colorScheme="orange"
						borderRadius="full"
						px="6px"
						pr={2}
						py="2px"
						textTransform="capitalize"
					>
						<Icon
							as={MdWarningAmber}
							color="orange.500"
							boxSize={4}
							aria-label="Warning"
						/>
						<Text fontWeight="400" fontSize="xs">
							Partially published
						</Text>
					</Badge>
				);
			}
		} else if (hasSpecificError) {
			badge = (
				<InfoIcon color="blue.500" boxSize={4} aria-label="Information" />
			);
		} else {
			badge = (
				<Icon
					as={MdWarningAmber}
					color="orange.500"
					boxSize={4}
					aria-label="Warning"
				/>
			);
		}

		return (
			<HStack
				w="full"
				justifyContent="space-between"
				spacing={0}
				alignItems="center"
				py="1"
				px={isAccordionButton ? 0 : 3}
			>
				<HStack spacing={1.5} alignItems="center">
					{channelIcon && (
						<Box
							boxSize={6}
							display="flex"
							alignItems="center"
							justifyContent="center"
						>
							{channelIcon}
						</Box>
					)}

					<Text>{channelName}</Text>

					{badge}
				</HStack>
				{(successStatus === 'success' || successStatus === 'partial') &&
					linkInfo && (
						<Link
							href={linkInfo.url}
							color="blue.500"
							fontSize="14px"
							isExternal
							mr={1}
						>
							{linkInfo.text}
						</Link>
					)}
			</HStack>
		);
	};

	if (comingSoon) {
		return (
			<VStack align="start" w="full" spacing={1} py={1}>
				{renderTitle()}
			</VStack>
		);
	}

	if (hasErrors) {
		return (
			<Accordion allowToggle w="full">
				<AccordionItem border="none">
					<AccordionButton px={3} py={1}>
						{renderTitle(true)}
						<AccordionIcon />
					</AccordionButton>
					<AccordionPanel pb={5} px={0}>
						<VStack align="start" spacing={2} pl={6}>
							{successStatus === 'partial' && (
								<Text fontSize="sm" color="gray.600">
									Some of your ads were published successfully but others
									require review
								</Text>
							)}
							{errors.map((error, index) => (
								<HStack key={index} alignItems="flex-start">
									<VStack align="start" spacing={0}>
										<Text
											fontWeight="bold"
											color="black"
											fontSize="sm"
											lineHeight="1.2"
										>
											{error.title}
										</Text>
										<Text
											color="black"
											fontSize="sm"
											lineHeight="1.2"
											maxW={480}
										>
											{error.message}
										</Text>
									</VStack>
								</HStack>
							))}
						</VStack>
					</AccordionPanel>
				</AccordionItem>
			</Accordion>
		);
	}

	return (
		<VStack align="start" w="full" spacing={1} py={2}>
			{renderTitle()}
		</VStack>
	);
};

export default ChannelStatusSection;
