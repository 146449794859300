import React, { useRef, useState } from 'react';
import { Button, Heading, Text } from '@chakra-ui/react';
import CustomModal from 'src/components/common/CustomModal';
import AccountSettingsForm from './AccountSettingsForm';
import { IAccountSettingsForm } from 'src/lib/schemas/account/settings';
import { toastError } from 'src/services/toast';

interface AccountSettingsModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSubmit: (formData: Partial<IAccountSettingsForm>) => void;
	accountSettingsFields?: Partial<IAccountSettingsForm>;
}

const AccountSettingsModal: React.FC<AccountSettingsModalProps> = ({
	isOpen,
	onSubmit,
	onClose,
	accountSettingsFields,
}) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const formRef = useRef<{ validateForm: () => Promise<boolean> } | null>(null);

	const handleSaveClick = async () => {
		try {
			const isValid = await formRef.current?.validateForm();

			if (isValid) {
				const accountSettingsSaveButton = document.getElementById(
					'account-settings-save-button',
				);
				accountSettingsSaveButton?.click();
				onClose();
			}
		} catch (error) {
			toastError(error);
		}
	};

	const modalHeader = (
		<>
			<Heading fontSize="xl" fontWeight="semibold">
				Company info
			</Heading>
			<Text size="md" mt={1}>
				Do you want to update your account with the following information?
			</Text>
		</>
	);

	const modalFooter = (
		<>
			<Button variant="orangeOutline" onClick={onClose}>
				No
			</Button>
			<Button variant="orangeSolid" ml={3} onClick={handleSaveClick}>
				Yes
			</Button>
		</>
	);

	const modalHeight = isDropdownOpen ? '600px' : 'auto';

	return (
		<CustomModal
			isOpen={isOpen}
			onClose={onClose}
			height={modalHeight}
			header={modalHeader}
			footer={modalFooter}
			withBorder={false}
			minWidth="800px"
		>
			<AccountSettingsForm
				ref={formRef}
				onSubmit={onSubmit}
				fields={accountSettingsFields}
				onDropdownToggle={setIsDropdownOpen}
			/>
		</CustomModal>
	);
};

export default AccountSettingsModal;
