import { createContext } from 'react';
import { ICampaign } from 'src/lib/schemas';
import { ILayer } from 'src/lib/schemas/account/template/TemplateSchema';
import { IIndustry } from 'src/services/industries';

export interface ILayerAction {
	id?: string;
	arguments?: any;
}

export interface ILayerSpec {
	name?: string;
	actions?: ILayerAction[];
}

export interface IVariation {
	id?: string;
	title?: string;
	isLoading?: boolean;
	layeredFile?: string;
	layerSpecs?: ILayerSpec[];
}

interface IPalette {
	strategy: {
		id: string;
	};
	options: Array<{
		name: string;
		colors: string[];
	}>;
}

export type Kind = 'Product' | 'Service';

interface ITemplateAttribute {
	mainIndustry?: string;
	industries?: string[];
	style?: string;
	season?: string;
	thumbnail?: string;
	palettes?: IPalette;
	fonts?: string[];
	tags?: string[];
	priority?: number;
	kind?: Kind;
}

export interface ITemplate {
	name?: string;
	id: string;
	enabled?: boolean;
	attributes?: ITemplateAttribute;
	variations?: IVariation[];
	scope?: 'platform' | 'account';
	score?: number;
}

interface IAccountInfo {
	folderName: string;
	existFolder: boolean;
}

interface IImageAttributes {
	dimensions: {
		width: number;
		height: number;
	};
	layeredFile: string;
	flatFile: string;
	layers: Array<any>;
}

export interface IUpdatePayload {
	variationId: string;
	attributes: {
		image: IImageAttributes;
	};
}

export interface IPayloadsByCase {
	idCase: number;
	payloadList: IUpdatePayload[];
}

interface ITemplateContext {
	templates: ITemplate[];
	templatesByScope: ITemplate[];
	styles: string[];
	seasons: string[];
	industries: IIndustry[];
	isOptionsReady: boolean;
	selectedIndustry: IIndustry | null;
	setSelectedIndustry: React.Dispatch<React.SetStateAction<IIndustry | null>>;
	industriesLoaded: boolean;
	fetchIndustries: () => Promise<void>;
	setIndustries: React.Dispatch<React.SetStateAction<IIndustry[]>>;
	setIndustriesLoaded: React.Dispatch<React.SetStateAction<boolean>>;
	selectedTemplate: ITemplate | null;
	setSelectedTemplate: (template: ITemplate | null) => void;
	fetchTemplatesByScope: (campaign?: ICampaign) => Promise<void>;
	selectedTemplateIds: string[];
	isSelectTemplateModalOpen: boolean;
	setSelectTemplateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	templatesLoaded: boolean;
	setTemplatesLoaded: React.Dispatch<React.SetStateAction<boolean>>;
	setSelectedTemplateIds: React.Dispatch<React.SetStateAction<string[]>>;
	payloads: IUpdatePayload[];
	setPayloads: React.Dispatch<React.SetStateAction<IUpdatePayload[]>>;
	payloadsByCase: IPayloadsByCase[];
	handleSetPayloadsByCase: (
		caseId: number,
		payload: IUpdatePayload,
		isRegenerate: boolean,
	) => void;
	setPayloadsByCaseState: React.Dispatch<
		React.SetStateAction<IPayloadsByCase[]>
	>;
	resetSelectedTemplate: () => void;
	layers: ILayer[];
	setLayers: React.Dispatch<React.SetStateAction<ILayer[]>>;
	loadingLayers: boolean;
	configuredLayers: { variationId: string; layerSpec: ILayerSpec[] }[];
	setConfiguredLayers: React.Dispatch<
		React.SetStateAction<{ variationId: string; layerSpec: ILayerSpec[] }[]>
	>;
	styleValues: string[];
	seasonsValues: string[];
	setStyleValues: React.Dispatch<React.SetStateAction<string[]>>;
	setSeasonValues: React.Dispatch<React.SetStateAction<string[]>>;
}

const TemplateContext = createContext<ITemplateContext>({
	templates: [],
	templatesByScope: [],
	styles: [],
	seasons: [],
	industries: [],
	isOptionsReady: false,
	selectedIndustry: null,
	setSelectedIndustry: () => {},
	industriesLoaded: false,
	fetchIndustries: async () => {},
	setIndustries: () => {},
	setIndustriesLoaded: () => {},
	selectedTemplate: null,
	setSelectedTemplate: () => {},
	fetchTemplatesByScope: async () => {},
	selectedTemplateIds: [],
	isSelectTemplateModalOpen: false,
	setSelectTemplateModalOpen: () => {},
	templatesLoaded: false,
	setTemplatesLoaded: () => {},
	setSelectedTemplateIds: () => {},
	payloads: [],
	setPayloads: () => {},
	payloadsByCase: [],
	handleSetPayloadsByCase: () => {},
	setPayloadsByCaseState: () => {},
	resetSelectedTemplate: () => {},
	layers: [],
	setLayers: () => {},
	loadingLayers: false,
	configuredLayers: [],
	setConfiguredLayers: () => {},
	styleValues: [],
	seasonsValues: [],
	setStyleValues: () => {},
	setSeasonValues: () => {},
});

export default TemplateContext;
