import React from 'react';
import ReactDOM from 'react-dom';
import { Box, Text, CloseButton, VStack, Icon } from '@chakra-ui/react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { isDesktop } from 'react-device-detect';

interface Props {
	onClose: () => void;
}

const DeviceNotSupported: React.FC<Props> = ({ onClose }) => {
	React.useEffect(() => {
		const isIOS =
			/iPad|iPhone|iPod/.test(navigator.userAgent) &&
			!navigator.userAgent.includes('Windows');
		if (isIOS) {
			const meta = document.createElement('meta');
			meta.name = 'viewport';
			meta.content =
				'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, shrink-to-fit=no';
			const head = document.getElementsByTagName('head')[0]
			head.appendChild(meta);
			return () => {	
				if (head.contains(meta)) {
					head.removeChild(meta);
				}
			};
		}
	}, []);

	const modalMessage = isDesktop
		? `shines in a maximized window!`
		: 'shines on Desktop!';
	const content = (
		<Box
			position="fixed"
			top="0"
			left="0"
			right="0"
			bottom="0"
			backgroundColor="rgba(0, 0, 0, 0.7)"
			zIndex="9999999"
			display="flex"
			alignItems="center"
			justifyContent="center"
			overflow="hidden"
			maxH="100vh"
			maxW="100vw"
		>
			<VStack
				spacing={4}
				textAlign="center"
				p={4}
				borderWidth={1}
				borderRadius="20px"
				backgroundColor="white"
				width="90vw"
				maxWidth="90vw"
				maxHeight="90vh"
				height="85vh"
				alignItems="center"
				justifyContent="center"
				fontSize={['14px', '16px', '18px']}
				overflowY="auto"
				position="relative"
				sx={{
					WebkitTextSizeAdjust: '100%',
					touchAction: 'manipulation',
					'& *': {
						maxHeight: '100%',
					},
				}}
			>
				<CloseButton
					position="absolute"
					top="4px"
					right="4px"
					onClick={onClose}
					size="lg"
					zIndex="1"
				/>
				<Icon as={AiOutlineInfoCircle} boxSize={['3rem', '4rem', '5rem']} />
				<Text
					fontSize={['20px', '24px', '30px']}
					fontWeight="semibold"
					lineHeight="1.2"
				>
					FusionAds.ai shines on Desktop!
				</Text>
				<Text
					fontSize={['14px', '16px', '18px']}
					lineHeight="1.5"
					maxWidth="90%"
				>
					{isDesktop ? (
						'For the full immersive experience, please maximize your window.'
					) : (
						<>
							For the full, immersive experience, please visit us directly at{' '}
							<a
								href="https://www.fusionads.ai"
								target="_blank"
								rel="noreferrer"
								style={{
									textDecoration: 'underline',
								}}
							>
								https://www.fusionads.ai
							</a>{' '}
							in a desktop browser.
						</>
					)}
				</Text>
			</VStack>
		</Box>
	);

	return ReactDOM.createPortal(content, document.body);
};

export default DeviceNotSupported;
