import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { MainLayout } from 'src/layout';
import {
	Dashboard,
	CompanyAccount,
	CreateCampaign,
	Integrations,
	ManageUsers,
	PendingVerification,
	Products,
	Brands,
	Categories,
	Campaigns,
	Groups,
	Apps,
	App,
} from 'src/pages';
import NotAllowedMessage from 'src/components/common/NotAllowedMessage';

// TODO: Remove redirect import and use import from src/pages
import RefreshPage from 'src/pages/misc/RefreshPage';
import authService from 'src/services/auth';
import { IUser } from 'src/lib/schemas';
import Home from 'src/pages/home/Home';
import Templates from 'src/pages/account/Templates';
import TemplatesForm from 'src/components/account/Templates/parts/TemplatesForm';
import FacebookCallback from 'src/pages/integrations/FacebookCallback';
import Tags from 'src/pages/commerce/Tags';
import CreateEditCampaign from 'src/pages/campaigns/CreateEditCampaign';

const getRoutesProtected = () => {
	const isAuth = authService.isAuthenticated();
	const pathname = useLocation().pathname;
	if (isAuth) {
		return <Outlet />;
	} else {
		return <Navigate to={`/login?redirect_url=${pathname}`} />;
	}
};

const isAdministrator = (user: IUser | null) =>
	user?.role === 'verifiedAccountAdmin' || user?.role === 'accountAdmin';

interface InstagramProtectedRouteProps {
	element: JSX.Element;
}

const InstagramProtectedRoute = ({ element }: InstagramProtectedRouteProps) => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const showInstagramRoute = searchParams.get('instagram') === 'true';

	return showInstagramRoute ? element : <Navigate to="/home" />;
};

// TODO: Remove this to a helper function when verified roles reseticions are back
// const isPendingVerification = (user: IUser | null) => !user?.isEmailVerified;

const getProtectedRoutes = (user: IUser | null) => ({
	element: getRoutesProtected(),
	children: [
		{
			element: (
				// isPendingVerification(user) ?
				// 	<PendingVerification />
				// ) : (
				<MainLayout />
			),
			// ),
			children: [
				{
					path: '/home',
					element: <Home />,
				},
				{
					path: '/integrations',
					element: <Integrations title="Integrations"/>,
				},
				{
					path: '/v1/integrations/facebook/callback',
					element: <FacebookCallback />,
				},
				{
					path: 'tasks',
					children: [
						{ path: '', element: <Apps /> },
						{ path: ':appId', element: <App /> },
						{ path: ':appId/:paramActionId', element: <App /> }
					],
				},
				{
					path: 'account',
					element: <Outlet />,
					children: [
						{
							path: 'company',
							element: isAdministrator(user) ? (
								<CompanyAccount />
							) : (
								<NotAllowedMessage />
							),
						},
						{
							path: 'users',
							element: isAdministrator(user) ? (
								<ManageUsers />
							) : (
								<NotAllowedMessage />
							),
						},
						{
							path: 'templates',
							children: [
								{ path: '', element: <Templates /> },
								{ path: 'edit/:id', element: <TemplatesForm /> },
								{ path: 'new', element: <TemplatesForm /> },
							],
						},
					],
				},
				{
					path: 'commerce',
					element: <Outlet />,
					children: [
						{ path: 'catalog', element: <Products /> },
						{ path: 'tags', element: <Tags /> },
					],
				},
				{
					path: 'projects',
					element: <Outlet />,
					children: [
						{
							path: 'campaigns',
							children: [
								{ path: '', element: <Campaigns /> },
								{ path: 'deprecated/:campaignId', element: <CreateCampaign /> },
								{
									path: ':campaignId',
									element: <CreateEditCampaign />,
								},
							],
						},
						{ path: 'groups', element: <Groups /> },
					],
				},
				{ path: 'refresh-page', element: <RefreshPage /> },
			],
		},
	],
});

export default getProtectedRoutes;
