import React, { createContext, useState, ReactNode } from 'react';

interface DomainContextProps {
    domainUrl: string;
    setDomainUrl: (url: string) => void;
}

export const DomainContext = createContext<DomainContextProps>({
    domainUrl: '',
    setDomainUrl: () => {},
});

interface DomainProviderProps {
    children: ReactNode;
}

export const DomainProvider: React.FC<DomainProviderProps> = ({ children }) => {
    const [domainUrl, setDomainUrl] = useState('');

    return (
        <DomainContext.Provider value={{ domainUrl, setDomainUrl }}>
            {children}
        </DomainContext.Provider>
    );
};
