import {
	Box,
	Button,
	VStack,
	Flex,
	Text,
	Skeleton,
	Image,
	FormErrorMessage,
	FormControl,
	Alert,
	AlertIcon,
} from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import {
	FileInput,
	SelectSearchInputHook,
	StringInputHook,
} from 'src/components/common/form';
import { zodResolver } from '@hookform/resolvers/zod';
import http from 'src/services/http';
import UserContext from 'src/contexts/UserContext';
import useAccountConfig from 'src/hooks/useAccountConfig';
import { IIndustry, getIndustries } from 'src/services/industries';
import {
	IDomainDataSchema,
	DomainDataSchema,
} from 'src/lib/schemas/scrap/domainData';
import {
	ECampaignPromotedType,
	ICompanyAccountUpdate,
	ICampaignInterpretations,
} from 'src/lib/schemas';
import { toastError, toastSuccess } from 'src/services/toast';
import { updateCompanyAccount } from 'src/services/account';
import { isEmpty, omit } from 'lodash';
import { FileRejection } from 'react-dropzone';
import { uploadFile } from 'src/services/fileUpload';
import FusionLoading from 'src/components/common/FusionLoading';
import {
	useEffect,
	useState,
	useCallback,
	useContext,
	useMemo,
	FormEvent,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { createOrUpdateCampaign, getCampaign } from 'src/services/campaign';
import { processCallback } from 'src/lib/utils/processCallback';
import CampaignContext from 'src/contexts/campaign/CampaignContext';
import {
	CHANNELS,
	defaultCampaign,
} from 'src/lib/schemas/campaign/defaultCampaign';

interface ScrapedInfoFormProps {
	data: any;
	isDataLoading?: boolean;
	websiteLink?: string;
	onDataSubmit?: () => void;
	errors: any;
	isManualEntry?: boolean;
}

const ScrapedInfoForm = ({
	data,
	isDataLoading,
	onDataSubmit,
	websiteLink,
	errors,
	isManualEntry = false,
}: ScrapedInfoFormProps) => {
	const [industries, setIndustries] = useState<IIndustry[] | null>(null);
	const [areFontsLoading, setAreFontsLoading] = useState(false);
	const [isLogoLoading, setIsLogoLoading] = useState(false);
	const { config } = useAccountConfig();
	const initialFontOptions = config?.fonts ?? [];
	const [fontOptions, setFontOptions] = useState(initialFontOptions);
	const { user, account, setAccount } = useContext(UserContext);
	const { setCampaign } = useContext(CampaignContext);
	const IMAGE_NOT_VALID_MESSAGE = 'Invalid image';
	const navigate = useNavigate();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const formMethods = useForm<IDomainDataSchema>({
		resolver: zodResolver(DomainDataSchema),
	});
	const { setValue, watch, setFocus, clearErrors, setError, formState } =
		formMethods;
	const font = watch('font');
	const logo = watch('logo');
	const logoHasError = formState.errors['logo'];

	const memoizedLogoError = useMemo(
		() => logoHasError?.message,
		[logoHasError],
	);

	useEffect(() => {
		if (memoizedLogoError !== undefined) {
			setError('logo', { message: memoizedLogoError.toString() });
		}
	}, [memoizedLogoError, setError]);

	useEffect(() => {
		clearErrors();
	}, [data, clearErrors]);

	const onFontsSuccess = (data: any) => {
		const font = data.fonts.length ? data.fonts[0] : undefined;
		setValue('font', font);
		setAreFontsLoading(false);
	};

	const fetchIndustries = useCallback(async () => {
		const response = await getIndustries();
		response && setIndustries(response);
	}, []);

	useEffect(() => {
		fetchIndustries();
	}, [fetchIndustries]);

	useEffect(() => {
		isDataLoading && setAreFontsLoading(true);
	}, [isDataLoading]);

	useEffect(() => {
		!data && !isDataLoading && setAreFontsLoading(false);
	}, [data, isDataLoading]);

	const industryAsOption = (industry: IIndustry) => {
		return {
			label: industry.name,
			value: industry.industryIds[0],
		};
	};

	useEffect(() => {
		if (!data) {
			setValue('name', '');
			setValue('industry', '');
			setValue('logo', '');
			setValue('font', '');
			setValue('keywords', []);
			return;
		}
		data.brand?.name && setValue('name', data.brand?.name);
		data.brand?.industry &&
			setValue('industry', industryAsOption(data.brand.industry));
		data.brand?.logo && setValue('logo', data.brand.logo);
		data.brand?.fontsExecution
			? processCallback(data.brand.fontsExecution).then(onFontsSuccess)
			: setAreFontsLoading(false);
		data.keywords?.length && setValue('keywords', data.keywords);
	}, [data]);

	const handleTypographyChange = useCallback(
		(url: any) => {
			setValue('font', url);
		},
		[setValue],
	);

	const handleFontChange = async (
		input: File | string,
		fileRejections?: FileRejection[],
	) => {
		if (fileRejections && fileRejections.length > 0) {
			console.error('File rejected', fileRejections);
			onImageError('File type not accepted');
			return;
		}
		if (input === '') {
			clearErrors('font');
			return;
		}

		setAreFontsLoading(true);
		try {
			const { imageUrl } = await uploadFile(input, true, 100, 30);
			setAreFontsLoading(false);
			clearErrors('font');
			setValue('font', imageUrl);

			setFontOptions((prevOptions) => [
				...prevOptions,
				{
					label: input instanceof File ? input.name : 'New font',
					value: imageUrl,
				},
			]);
		} catch (error: any) {
			console.error('Error uploading file', error);
			onImageError(error.response?.data?.message || IMAGE_NOT_VALID_MESSAGE);
			setAreFontsLoading(false);
		}
	};

	const handleLogoChange = async (
		input: File | string,
		fileRejections?: FileRejection[],
	) => {
		const minWidth = 100;
		const minHeight = 30;

		if (fileRejections && fileRejections.length > 0) {
			console.error('File rejected', fileRejections);
			onImageError('File type not accepted');
			return;
		}
		if (input === '') {
			clearErrors('logo');
			return;
		}

		setIsLogoLoading(true);
		try {
			const { imageUrl } = await uploadFile(input, true, minWidth, minHeight);
			setIsLogoLoading(false);
			clearErrors('logo');
			setValue('logo', imageUrl);
		} catch (error: any) {
			console.error('Error uploading file', error);
			onImageError(error.response?.data?.message || IMAGE_NOT_VALID_MESSAGE);
			setIsLogoLoading(false);
		}
	};

	const getIndustryById = (id: string) =>
		industries?.find((industry) => industry.industryIds.includes(id));

	const mapIndustry = (industry: IIndustry) =>
		omit(industry, 'children', 'parentId', 'industryId', 'categoryId');

	const handleIndustryChange = (industryId: any) => {
		const industry = getIndustryById(industryId);
		industry && setValue('industry', mapIndustry(industry));
	};

	const handleSubmitForm = formMethods.handleSubmit(async (formData) => {
		if (!user || !account || !isEmpty(errors)) {
			return;
		}

		if (logoHasError) {
			setFocus('logo');
			return;
		}

		const { name, logo, font, industry, keywords } = formData;
		const description = formData.description || data?.description;
		const industryObj = getIndustryById(industry.value);
		const mappedIndustry = mapIndustry(industryObj!);
		const mappedLogo = isEmpty(logo) ? undefined : logo;
		const mappedFont = isEmpty(font) ? undefined : font;

		const completedFormData: ICompanyAccountUpdate = {
			account: account.id,
			websiteLink: websiteLink!,
			industry: mappedIndustry,
			name,
			description,
			logo: mappedLogo,
			brandIdentity: { fontType: mappedFont },
			keywords,
		};
		setIsSubmitting(true);
		try {
			const newCampaignData = isManualEntry
				? {
						...omit(defaultCampaign, [
							'id',
							'status',
							'lastUpdatedBy',
							'updatedAt',
							'group',
						]),
						channels: CHANNELS,
				  }
				: {
						...omit(defaultCampaign, [
							'id',
							'status',
							'title',
							'description',
							'lastUpdatedBy',
							'updatedAt',
							'group',
						]),
						title: data.title,
						description: description,
						destination: websiteLink,
						channels: CHANNELS,
						interpretations: {
							url_source: websiteLink,
						},
				  };

			const newCampaign = await createOrUpdateCampaign(newCampaignData, 'new');

			navigate(`/projects/campaigns/${newCampaign.id}`, {
				state: isManualEntry
					? {}
					: {
							catalogCallback: data.catalogsExecution,
							websiteLink: websiteLink,
							tonesAndAudienceCallback: data.deduceTonesAudiencesExecution,
					  },
			});

			const updatedAccount = await updateCompanyAccount(completedFormData);
			toastSuccess('Your changes have been saved');
			setAccount(updatedAccount);

			if (!isManualEntry) {
				const url_industry = {
					industryIds: industryObj?.industryIds ?? [],
					name: industryObj?.name ?? '',
					parentId: industryObj?.parentId ?? null,
					categoryId: industryObj?.categoryId ?? 'other',
					entityType: industryObj?.entityType ?? '',
				};

				await createOrUpdateCampaign(
					{
						interpretations: {
							url_industry,
							url_source: websiteLink,
							url_keywords: keywords ?? [],
						},
					},
					newCampaign.id,
				);
			}

			const refreshedCampaign = await getCampaign(newCampaign.id);
			setCampaign(refreshedCampaign);
		} catch (error: any) {
			const { message } = error.response?.data || {
				message: 'An error occurred.',
			};
			console.error('Error submitting form:', message);
			toastError(message);
		}
	});

	const loadCustomFont = useCallback(async () => {
		if (font) {
			const customFont = new FontFace('CustomFont', `url(${font})`);
			await customFont.load();
			document.fonts.add(customFont);
		}
	}, [font]);

	useEffect(() => {
		loadCustomFont();
	}, [font, loadCustomFont]);

	const industriesOptions = industries?.map((industry) => ({
		label: industry.name,
		value: industry.industryIds[0],
	}));

	const onImageError = (error?: string) => {
		const message = error ?? IMAGE_NOT_VALID_MESSAGE;
		setError('logo', { message });
		setFocus('logo');
	};

	return (
		<FormProvider {...formMethods}>
			<Box
				onSubmit={(e: FormEvent<HTMLDivElement>) => e.preventDefault()}
				width="full"
			>
				<VStack spacing={4} align="flex-start">
					<StringInputHook
						label="Business Name"
						name="name"
						placeholder="Enter company name"
						inputProps={{ w: '400px' }}
						required
						isLoading={isDataLoading}
					/>
					<Box w="400px">
						<SelectSearchInputHook
							name="industry"
							placeholder="Select/Search"
							options={industriesOptions ?? []}
							label="What do you sell?"
							required
							onValueChangeCallback={handleIndustryChange}
							valueAsObject
							isLoading={isDataLoading}
						/>
					</Box>
					<Flex flex={1} alignItems="flex-end" w="full">
						<StringInputHook
							name="logo"
							label="Logo (Optional)"
							requirementsLabel="Should be at least 100x100px and a quality higher than 150dpi"
							placeholder="Enter Logo URL"
							isLoading={isDataLoading}
							withErrorMessage={false}
							formControlProps={{ w: 'auto' }}
							inputProps={{
								onChange: (e) => handleLogoChange(e.target.value),
								w: '400px',
							}}
						/>
						<Flex
							justify="center"
							my="12px"
							h="8px"
							alignItems="flex-end"
							gap={5}
						>
							<Box flex={1} h="1px" bg="#E2E8F0" />
							<Text fontSize="12px" fontWeight={400}>
								Or
							</Text>
							<Box flex={1} h="1px" bg="#E2E8F0" />
						</Flex>
						<Flex
							alignItems="flex-end"
							gap={10}
							w="full"
							justifyContent="space-between"
						>
							<FileInput
								name="logo"
								acceptImages
								uploadButtonText={logo ? 'Change image' : 'Upload image'}
								onUrlChange={handleLogoChange}
								uploadPath={`${user?.account}/logo`}
								onDrop={(acceptedFiles, fileRejections) =>
									handleLogoChange(acceptedFiles[0], fileRejections)
								}
								onLoading={setIsLogoLoading}
							/>
							{isDataLoading && (
								<Skeleton w="100px" h="100px" borderRadius="md" mt={5} />
							)}
							<FusionLoading
								isLoading={isLogoLoading}
								imageProps={{ maxW: '90px', maxH: '90px' }}
							/>
							{logo && !logoHasError && !isLogoLoading && (
								<Image
									src={logo}
									alt="Account logo"
									maxW="90px"
									maxH="90px"
									alignSelf="flex-end"
									borderRadius="md"
								/>
							)}
						</Flex>
					</Flex>
					<FormControl mt={-4} isInvalid={!!logoHasError}>
						<FormErrorMessage>{memoizedLogoError}</FormErrorMessage>
					</FormControl>
					{/* <Flex flex={1} alignItems="flex-end" w="full">
						<Box minW="400px">
							<SelectSearchInputHook
								name="font"
								label="Font (Optional)"
								placeholder="Select/Search"
								options={fontOptions}
								onChange={handleTypographyChange}
								isLoading={areFontsLoading}
								value={font}
								isClearable
							/>
						</Box>
						<Flex
							justify="center"
							my="12px"
							h="8px"
							alignItems="flex-end"
							gap={5}
						>
							<Box flex={1} h="1px" bg="#E2E8F0" />
							<Text fontSize="12px" fontWeight={400}>
								Or
							</Text>
							<Box flex={1} h="1px" bg="#E2E8F0" />
						</Flex>
						<Flex
							gap={10}
							alignItems="flex-end"
							justifyContent="space-between"
							w="full"
						>
							<FileInput
								name="font"
								acceptFonts
								uploadButtonText="Upload font"
								uploadPath={`${user?.account}/fontType`}
								onUrlChange={handleTypographyChange}
								onDrop={(acceptedFiles, fileRejections) =>
									handleFontChange(acceptedFiles[0], fileRejections)
								}
							/>
							{areFontsLoading && (
								<Skeleton h="100px" w="full" maxW="100px" borderRadius="md" />
							)}
							{font && !areFontsLoading && (
								<Text
									display="flex"
									wordBreak="break-word"
									fontFamily="CustomFont"
									alignItems="flex-end"
								>
									ABCDEFGHIJKLMNOPQRSTUVWXYZ
									<br />
									abcdefghijklmnopqrstuvwxyz
								</Text>
							)}
						</Flex>
					</Flex> */}
					{data?.catalogsFound > 0 && (
						<Alert status="info" borderRadius="md" p={2} maxW={607}>
							<AlertIcon boxSize="17px" ml={3} mr={2} />
							<Box fontSize="14px">
								We found products in your website, you can find them under the
								Commerce menu
							</Box>
						</Alert>
					)}

					<Flex w="full" justifyContent="flex-end">
						<Button
							mt={4}
							variant="orangeSolid"
							onClick={handleSubmitForm}
							isLoading={isSubmitting}
						>
							Confirm
						</Button>
					</Flex>
				</VStack>
			</Box>
		</FormProvider>
	);
};

export default ScrapedInfoForm;
