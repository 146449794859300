import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Container, Flex, Button, useDisclosure } from '@chakra-ui/react';
import LaunchingCampaignModal from './parts/LaunchingCampaignModal';
import CampaignSuccessModal from './parts/CampaignSuccessModal';
import { CampaignContext } from 'src/contexts';
import { validateCampaignSchedule } from '../utils/validations';
import IntegrationsModal from './parts/IntegrationsModal';
import { useLocation } from 'react-router-dom';
import { useMyIntegrations } from 'src/contexts/integration/IntegrationContext';
import {
	ECampaignScheduleType,
	ILaunchResponse,
	ICreative,
	IDesignDirection,
	IFailedCreative,
	IError,
	IPublishedCreative,
} from 'src/lib/schemas/campaign/newFlowCampaign';
import {
	genrateCampaignCreatives,
	getCampaign,
	launchCampaign,
} from 'src/services/campaign';
import { delay } from 'src/lib/utils';
import { countPendingCreativesByChannel } from '../utils/countPendingCreativesByChannel';
import http from 'src/services/http';
import { useForm } from 'react-hook-form';

interface LaunchCampaignProps {
	isValidCampaignConfig: boolean;
	isValidCampaignBudget: boolean;
	isSocialEnabled?: boolean;
	onLaunch: () => Promise<ILaunchResponse>;
	setShowCreativesLoader: (val: boolean) => void;
	pendingCreativesCount: number;
	designDirections: IDesignDirection[];
	creatives: ICreative[];
	selectedChannels: string[];
	setPendingCreativesCount: React.Dispatch<React.SetStateAction<number>>;
}

const LaunchCampaign: FC<LaunchCampaignProps> = ({
	isValidCampaignConfig,
	isValidCampaignBudget,
	isSocialEnabled,
	onLaunch,
	selectedChannels = [],
	setShowCreativesLoader,
	pendingCreativesCount,
	designDirections,
	creatives,
	setPendingCreativesCount,
}) => {
	const {
		isOpen: isOpenLaunchingModal,
		onOpen: onOpenLaunchingModal,
		onClose: onCloseLaunchingModal,
	} = useDisclosure();
	const {
		isOpen: isOpenSuccessModal,
		onOpen: onOpenSuccessModal,
		onClose: onCloseSuccessModal,
	} = useDisclosure();
	const {
		isOpen: isOpenIntegrationsModal,
		onOpen: onOpenIntegrationsModal,
		onClose: onCloseIntegrationsModal,
	} = useDisclosure();
	const {
		campaign,
		setCampaign,
		setIsLaunching,
		setOpenSections,
		id: campaignId,
	} = useContext(CampaignContext);
	const { integrations, shouldLaunch, setShouldLaunch } = useMyIntegrations();
	const { search } = useLocation();
	const redirectedFromFB = search.includes('fromFB');
	const redirectedFromGoogle = search.includes('fromGoogle');
	const [failedCreatives, setFailedCreatives] = useState<IFailedCreative[]>([]);
	const [publishedCreatives, setPublishedCreatives] = useState<
		IPublishedCreative[]
	>([]);
	const { handleSubmit } = useForm();
	const [isLaunchInitiated, setIsLaunchInitiated] = useState(false);
	const [errorMessages, setErrorMessages] = useState<string[]>([]);
	const [modalTitle, setModalTitle] = useState<
		'SUCCESS' | 'ERROR' | 'PARTIAL PUBLISHED'
	>('SUCCESS');
	const publishButtonRef = useRef<HTMLButtonElement | null>(null);
	const isDesignDirectionPending = designDirections?.some(
		(c) => c.status === 'pending' || c.status === 'generating',
	);
	const isCreativesPending = creatives?.some(
		(c) => c.status === 'pending' || c.status === 'generating',
	);
	const [isPublishButtonLoading, setIsPublishButtonLoading] = useState(false);
	const facebookChannels = [
		'facebook',
		'facebookPaid',
		'instagram',
		'instagramPaid',
	];
	const googleChannels = ['google'];
	const channelsFromProps = Array.isArray(selectedChannels)
		? selectedChannels
		: [];
	const selectedChannelsFallback =
		campaign && Array.isArray(campaign.channels) ? campaign.channels : [];
	const effectiveSelectedChannels =
		channelsFromProps.length > 0 ? channelsFromProps : selectedChannelsFallback;

	const needsFacebookIntegration = effectiveSelectedChannels.some((channel) =>
		facebookChannels.includes(channel),
	);

	const fbPageId = integrations?.social?.fb?.attributes?.pagesToPublishContent
		?.length
		? integrations.social.fb.attributes.pagesToPublishContent[0].id
		: undefined;

	const igName = integrations?.social?.fb?.attributes?.instagramPagesToPublish
		?.length
		? integrations.social.fb.attributes.instagramPagesToPublish[0].name
		: undefined;

	const needsGoogleIntegration = effectiveSelectedChannels.some((channel) =>
		googleChannels.includes(channel),
	);

	const handleCloseSuccessModal = () => {
		onCloseSuccessModal();
		setErrorMessages([]);
	};
	const handlePublishClick = () => {
		setIsPublishButtonLoading(true);

		setTimeout(() => {
			startLaunch();
			setIsPublishButtonLoading(false);
		}, 500);
	};

	const isFacebookConnected =
		integrations?.social?.fb?.enabled &&
		integrations?.social?.fb?.attributes?.pagesToPublishContent?.length > 0;

	const isGoogleConnected =
		integrations?.social?.google?.enabled &&
		integrations?.social?.google?.attributes &&
		Array.isArray(integrations.social.google.attributes.customers) &&
		integrations.social.google.attributes.customers.length > 0;

	useEffect(() => {
		const count = countPendingCreativesByChannel(campaign, creatives);
		setPendingCreativesCount(count);
	}, [campaign, creatives, setPendingCreativesCount]);

	useEffect(() => {
		if (redirectedFromFB || redirectedFromGoogle) {
			onOpenIntegrationsModal();
		}
	}, [redirectedFromFB, redirectedFromGoogle, onOpenIntegrationsModal]);

	useEffect(() => {
		if (shouldLaunch) {
			onCloseIntegrationsModal();
			startLaunch(true);
			setShouldLaunch(false);
		}
	}, [shouldLaunch, onCloseIntegrationsModal, setShouldLaunch]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				isLaunchInitiated &&
				publishButtonRef.current &&
				!publishButtonRef.current.contains(event.target as Node)
			) {
				setIsLaunchInitiated(false);
			}
		};

		if (isLaunchInitiated) {
			document.addEventListener('click', handleClickOutside);
		}

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [isLaunchInitiated]);

	const isValidCampaignSchedule = useMemo(() => {
		if (!campaign?.schedule) return false;
		return validateCampaignSchedule(campaign.schedule);
	}, [campaign]);

	const placementIdToChannelIdMap: { [key: string]: string } = {
		facebookFeedSocialMedia: 'facebook',
		facebookStorySocialMedia: 'facebook',
		facebookFeedPaidAd: 'facebookPaid',
		facebookStoryPaidAd: 'facebookPaid',
		instagramFeedSocialMedia: 'instagram',
		instagramStorySocialMedia: 'instagram',
		instagramFeedPaidAd: 'instagramPaid',
		instagramStoryPaidAd: 'instagramPaid',
		googlePMAX: 'google',
	};

	const getChannelIdsFromCreative = (
		creative: IFailedCreative | IPublishedCreative,
	): string[] => {
		const channelIds: string[] = [];

		if (creative.channel === 'meta') {
			if (creative.placementId) {
				const placementChannel =
					placementIdToChannelIdMap[creative.placementId];
				if (placementChannel) channelIds.push(placementChannel);
			} else {
				channelIds.push('meta');
			}
		} else if (creative.channel === 'metaAds') {
			channelIds.push('facebookPaid', 'instagramPaid');
		} else if (creative.channel) {
			channelIds.push(creative.channel);
		}
		return channelIds;
	};

	const hasPublishedFacebookCreatives = publishedCreatives.some((creative) => {
		const channels = getChannelIdsFromCreative(creative);
		return channels.some((channel) => facebookChannels.includes(channel));
	});

	const hasPublishedGoogleCreatives = publishedCreatives.some((creative) => {
		const channels = getChannelIdsFromCreative(creative);
		return channels.some((channel) => googleChannels.includes(channel));
	});

	const showFacebookLinks =
		needsFacebookIntegration &&
		isFacebookConnected &&
		hasPublishedFacebookCreatives;

	const showGoogleLinks =
		needsGoogleIntegration && isGoogleConnected && hasPublishedGoogleCreatives;

	const startLaunch = async (skipIntegrationValidation = false) => {
		if (!campaign || !campaignId) return;

		const updatedCampaign = {
			...campaign,
			channels: selectedChannels,
		};
		setCampaign(updatedCampaign);

		if (!validateCampaign(skipIntegrationValidation)) return;

		setIsLaunchInitiated(true);
		onOpenLaunchingModal();

		if (!areAllDesignDirectionsWithCreatives() || isCreativesPending) {
			setShowCreativesLoader(true);
			try {
				await genrateCampaignCreatives(campaignId, '', true);
				const data = await getCampaign(campaignId);
				setCampaign(data);
			} catch (error) {
				console.error('Error generating creatives:', error);
				setShowCreativesLoader(false);
			}
		} else {
			await finishLaunch();
		}
	};

	const validateCampaign = (skipIntegrationValidation: boolean): boolean => {
		if (!isValidCampaignConfig) {
			handleInvalidCampaignConfig();
			return false;
		}

		if (!isValidCampaignSchedule) {
			handleInvalidCampaignSchedule();
			return false;
		}

		if (!isValidCampaignBudget) {
			return false;
		}

		if (isOneOffScheduleWithoutEndDate()) {
			setErrorMessages(['End Date is required for One-off campaigns']);
			return false;
		}

		if (!skipIntegrationValidation && shouldOpenIntegrationsModal()) {
			onOpenIntegrationsModal();
			return false;
		}

		return true;
	};

	useEffect(() => {
		if (isLaunchInitiated && pendingCreativesCount > 0) {
			handlePendingCreatives();
		}
	}, [pendingCreativesCount, isLaunchInitiated]);

	const handlePendingCreatives = async () => {
		if (!campaignId) return;

		let attemptCount = 0;
		const maxAttempts = 30;

		let updatedPendingCreativesCount =
			creatives?.filter(
				(creative) =>
					creative.status === 'pending' || creative.status === 'generating',
			).length || 0;

		while (
			updatedPendingCreativesCount > 0 ||
			!areAllDesignDirectionsWithCreatives()
		) {
			await delay(1000);
			const data = await getCampaign(campaignId);
			setCampaign(data);
			if (!data.creatives) return;

			updatedPendingCreativesCount = data.creatives.filter(
				(creative) =>
					creative.status === 'pending' || creative.status === 'generating',
			).length;

			attemptCount++;

			if (attemptCount >= maxAttempts) {
				await http.post('/v2/migrations/process-pending-creatives');
			}
		}

		setShowCreativesLoader(false);
		await finishLaunch();
	};

	const finishLaunch = async () => {
		setIsLaunching(true);
		try {
			const response = await onLaunch();

			let publishedCreativesData: IPublishedCreative[] = [];
			let failedCreativesData: IFailedCreative[] = [];
			let message: string | undefined = undefined;

			if (response.result) {
				publishedCreativesData = response.result.publishedCreatives;
				failedCreativesData = response.result.failedCreatives;
				message = response.message;
			} else {
				publishedCreativesData = response.publishedCreatives || [];
				failedCreativesData = response.failedCreatives || [];
				message = response.message;
			}

			setPublishedCreatives(publishedCreativesData);
			setFailedCreatives(failedCreativesData);

			if (
				failedCreativesData.length > 0 &&
				publishedCreativesData.length === 0
			) {
				setModalTitle('ERROR');
			} else if (
				failedCreativesData.length > 0 &&
				publishedCreativesData.length > 0
			) {
				setModalTitle('PARTIAL PUBLISHED');
			} else if (publishedCreativesData.length > 0) {
				setModalTitle('SUCCESS');
			} else {
				setModalTitle('SUCCESS');
			}

			if (failedCreativesData.length > 0) {
				if (message) {
					const messagesArray = message.split('|');
					setErrorMessages(messagesArray);
				} else {
					const messagesArray = failedCreativesData.flatMap(
						(fc: IFailedCreative) => fc.errors.map((e: IError) => e.message),
					);
					setErrorMessages(messagesArray);
				}
			} else {
				setErrorMessages([]);
			}
		} catch (error: any) {
			if (error?.response?.status >= 500  || error?.response?.statusCode >= 500 ) {
				setModalTitle('ERROR');
				setFailedCreatives(
					selectedChannels.map((channel) => ({
						channel,
						errors: [{ message: "We couldn't published this ad, please try again" }],
						status: 'failed',
					})),
				);
				setPublishedCreatives([]);
			} else {
				const backendResult = error?.response?.data
					?.result as ILaunchResponse['result'];
				const backendMessage = error?.response?.data?.message as
					| string
					| undefined;

				const backendFailedCreatives = backendResult?.failedCreatives || [];
				const backendPublishedCreatives =
					backendResult?.publishedCreatives || [];

				setFailedCreatives(backendFailedCreatives);
				setPublishedCreatives(backendPublishedCreatives);

				if (
					backendFailedCreatives.length > 0 &&
					backendPublishedCreatives.length > 0
				) {
					setModalTitle('PARTIAL PUBLISHED');
					if (backendMessage) {
						const messagesArray = backendMessage.split('|');
						setErrorMessages(messagesArray);
					} else {
						const messagesArray = backendFailedCreatives.flatMap(
							(fc: IFailedCreative) => fc.errors.map((e: IError) => e.message),
						);
						setErrorMessages(messagesArray);
					}
				} else {
					setModalTitle('ERROR');
					if (backendMessage) {
						const messagesArray = backendMessage.split('|');
						setErrorMessages(messagesArray);
					} else {
						const messagesArray = backendFailedCreatives.flatMap(
							(fc: IFailedCreative) => fc.errors.map((e: IError) => e.message),
						);
						setErrorMessages(messagesArray);
					}
				}
			}
		} finally {
			onCloseLaunchingModal();
			onOpenSuccessModal();
			setIsLaunching(false);
		}
	};

	const handleInvalidCampaignConfig = () => {
		setOpenSections([true, false, false]);
		const configFormSubmitElement = document.getElementById(
			'campaign-config-form-submit',
		);
		configFormSubmitElement?.click();
	};

	const handleInvalidCampaignSchedule = () => {
		const scheduleFormSubmitElement = document.getElementById(
			'campaign-schedule-form-submit',
		);
		scheduleFormSubmitElement?.click();
	};

	const isOneOffScheduleWithoutEndDate = () => {
		return (
			campaign?.schedule?.type === ECampaignScheduleType['One-off'] &&
			!campaign.schedule.endDate
		);
	};

	const shouldOpenIntegrationsModal = () => {
		if (!isOpenLaunchingModal) {
			if (
				(needsFacebookIntegration && !isFacebookConnected) ||
				(needsGoogleIntegration && !isGoogleConnected)
			) {
				return true;
			}
		}
		return false;
	};

	const areAllDesignDirectionsWithCreatives = () => {
		if (!campaign || !designDirections || !creatives) {
			return false;
		}

		const designDirectionIds = new Set(
			designDirections.map((direction: IDesignDirection) => direction.id),
		);

		const creativeDesignDirectionIds = new Set(
			creatives.map((creative: ICreative) => creative.designDirectionId),
		);

		let allDirectionsWithCreatives = true;

		designDirectionIds.forEach((id) => {
			if (!creativeDesignDirectionIds.has(id)) {
				allDirectionsWithCreatives = false;
			}
		});

		return allDirectionsWithCreatives;
	};

	return (
		<>
			<LaunchingCampaignModal isOpen={isOpenLaunchingModal} />
			<CampaignSuccessModal
				isOpen={isOpenSuccessModal}
				onClose={handleCloseSuccessModal}
				title={modalTitle}
				showFacebookLinks={showFacebookLinks}
				showGoogleLinks={showGoogleLinks}
				failedCreatives={failedCreatives}
				publishedCreatives={publishedCreatives}
				selectedChannels={selectedChannels}
				campaignName={campaign?.description || ''}
				fbPageId={fbPageId}
				igName={igName}
			/>

			<IntegrationsModal
				isOpen={!isOpenLaunchingModal && isOpenIntegrationsModal}
				onClose={onCloseIntegrationsModal}
				redirectedFromFB={redirectedFromFB}
				redirectedFromGoogle={redirectedFromGoogle}
			/>
			<Container maxW="full" py={6} m={0} px={0}>
				<Flex justifyContent="right" alignItems="flex-end">
					<Button
						ref={publishButtonRef}
						variant="orangeSolid"
						_hover={{
							opacity: 0.9,
						}}
						_disabled={{
							opacity: 0.7,
							cursor: 'not-allowed',
						}}
						onClick={handleSubmit(handlePublishClick)}
						isDisabled={isDesignDirectionPending || isPublishButtonLoading}
						isLoading={isPublishButtonLoading}
					>
						Publish to the channels
					</Button>
				</Flex>
			</Container>
		</>
	);
};

export default LaunchCampaign;
